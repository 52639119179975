import classNames from "classnames";
import { Emoji } from "./Emoji";

export const ReactionsChart = ({ campaign }) => {
    const totalReactions =
        (campaign.veryNegativeReactions || 0) +
        (campaign.negativeReactions || 0) +
        (campaign.neutralReactions || 0) +
        (campaign.positiveReactions || 0) +
        (campaign.veryPositiveReactions || 0);

    return (
        <div className="h-12 rounded-md overflow-hidden">
            {campaign.veryNegativeReactions > 0 && (
                <ReactionsChartBar
                    total={totalReactions}
                    value={campaign.veryNegativeReactions}
                    className="bg-red/40 hover:bg-red/60"
                    emoji="hmhmhmhm"
                />
            )}
            {campaign.negativeReactions > 0 && (
                <ReactionsChartBar
                    total={totalReactions}
                    value={campaign.negativeReactions}
                    className="bg-red/20 hover:bg-red/60"
                    emoji="sleepy"
                />
            )}
            {campaign.neutralReactions > 0 && (
                <ReactionsChartBar
                    total={totalReactions}
                    value={campaign.neutralReactions}
                    className="bg-white/20 hover:bg-white/40"
                    emoji="neutral"
                />
            )}
            {campaign.positiveReactions > 0 && (
                <ReactionsChartBar
                    total={totalReactions}
                    value={campaign.positiveReactions}
                    className="bg-cash/20 hover:bg-cash/40"
                    emoji="happy"
                />
            )}
            {campaign.veryPositiveReactions > 0 && (
                <ReactionsChartBar
                    total={totalReactions}
                    value={campaign.veryPositiveReactions}
                    className="bg-cash/40 hover:bg-cash/60"
                    emoji="stunned"
                />
            )}
        </div>
    );
};

const ReactionsChartBar = ({ total, value, className, emoji }) => {
    const width = (value / total) * 100;

    return (
        <div
            className={classNames(
                "relative float-left h-full transition-all",
                className
            )}
            style={{ width: `${width}%` }}
        >
            {!!emoji && width > 20 && (
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 pointer-events-none">
                    <Emoji emoji={emoji} className="h-8" />
                </div>
            )}
        </div>
    );
};
