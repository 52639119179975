import classNames from "classnames";
import { Icon } from "./Icon";

export const Message = ({ variant, title, children, icon, className }) => {
    let titleColor = "";
    let textColor = "";
    let iconColor = "";
    let bgColor = "";

    switch (variant) {
        case "positive":
            textColor = "text-green-500/80";

            titleColor = "text-green-500";
            iconColor = "!text-green-500";
            bgColor = "bg-green-500";
            break;
        case "negative":
            textColor = "text-red/80";

            titleColor = "text-red";
            iconColor = "!text-red";
            bgColor = "bg-red";
            break;
        case "blue":
            textColor = "text-blue/80";

            titleColor = "text-blue";
            iconColor = "!text-blue";
            bgColor = "bg-blue";
            break;
        case "warning":
            textColor = "text-orange-500/80";

            titleColor = "text-orange-500";
            iconColor = "!text-orange-500";
            bgColor = "bg-orange-500";
            break;
        case "yellow":
            textColor = "text-yellow/90";

            titleColor = "text-yellow";
            iconColor = "!text-yellow";
            bgColor = "bg-yellow/10";
            break;
        case "muted":
        default:
            textColor = "text-secondary";

            titleColor = "text-primary";
            iconColor = "!text-tertiary";
            bgColor = "bg-white/5";
    }

    return (
        <div
            className={classNames(
                `${bgColor} bg-opacity-20 p-2 rounded-xl grid items-center border border-white border-opacity-5`,
                {
                    "grid-cols-[1fr_auto]": !!icon && !!children,
                },
                className
            )}
        >
            <div>
                {!!title && (
                    <b className={classNames(`block mb-2`, titleColor)}>
                        {title}
                    </b>
                )}
                <div className={textColor}>{children}</div>
            </div>
            {!!icon && (
                <Icon
                    icon={icon}
                    className={classNames(iconColor, "text-3xl ml-8")}
                />
            )}
        </div>
    );
};
