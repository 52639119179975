import { useContext } from "react";
import { Hero } from "./elements/Hero";
import { Paragraph } from "./elements/Paragraph";
import classNames from "classnames";
import { StageEditorContext } from "./StageEditorContext";
import { Headline } from "./elements/Headline";
import { Icon } from "../Icon";
import {
    faCog,
    faEllipsis,
    faGripVertical,
    faPlus,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDrag } from "react-dnd";
import { Links } from "./elements/Links";
import { ProductSlider } from "./elements/ProductSlider";
import { StageElementContextProvider } from "../context/StageElementContext";
import { getElementConfigFromType } from "lib/src/stage/elements";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Popover } from "../Popover";
import { IconButton } from "../IconButton";
import { AddMVPElementButton } from "./controls/MVP";
import useQuestion from "../../hooks/useQuestion";

const keyElementMap = {
    "hero": Hero,
    "paragraph": Paragraph,
    "headline": Headline,
    "links": Links,
    "productSlider": ProductSlider,
};

export const StageElement = ({
    element,
    onClick,
    draggable,
    isEditor,
    isEditing,
    value,
    ...props
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { t } = useTranslation();
    const { QuestionDialog, question } = useQuestion();

    const [{ isDragging }, dragRef] = useDrag(
        () => ({
            type: "element",
            item: element,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [element]
    );

    if (!element) {
        return null;
    }

    const ElementComponent = keyElementMap[element.type];

    if (!ElementComponent) {
        console.error(`Element component ${element.type} not found`);

        return null;
    }

    const elementConfig = getElementConfigFromType(element.type);

    if (!elementConfig) {
        return null;
    }

    const renderAddButtons = Object.entries(elementConfig.arguments || {})
        .filter(([key, entry]) => entry.type === "mvp")
        .map(([key, entry]) => (
            <AddMVPElementButton
                variant="secondary"
                size="small"
                className="w-full px-3 py-2"
                wrapperClassName="w-full"
                rightIcon={faPlus}
                grow
                elementConfig={elementConfig}
                mvpKey={key}
                iconProps={{}}
            >
                {t("button.add_item")}
            </AddMVPElementButton>
        ));

    const handleDelete = async () => {
        if (
            !(await question(
                t("title.delete_stage_element"),
                t("question.delete_stage_element", {
                    element: `${t(elementConfig.label)} #${value.id}`,
                }),
                t("button.delete"),
                t("button.cancel"),
                "destructive"
            ))
        ) {
            return;
        }

        dispatch({ type: "DELETE_ELEMENT", payload: value });
    };

    const settingsArguments = Object.entries(
        elementConfig?.arguments || {}
    ).reduce(
        (prev, [key, entry]) =>
            entry?.inSettings ? { ...prev, [key]: entry } : prev,
        {}
    );

    const hasSettingsArguments = Object.keys(settingsArguments).length > 0;

    return (
        <StageElementContextProvider
            elementConfig={elementConfig}
            isEditing={isEditing}
            isEditor={isEditor}
            value={value}
        >
            <div
                className={classNames("relative grid items-center w-full", {
                    "grid-cols-[auto_1fr]": draggable,
                    "grid-cols-1": !draggable,
                })}
            >
                {draggable && (
                    <div ref={draggable && dragRef} className="cursor-move">
                        <Icon
                            icon={faGripVertical}
                            size="sm"
                            className="!text-tertiary mr-4 ml-1"
                        />
                    </div>
                )}
                <div
                    className={classNames({
                        "border-l-2 border-white/5 mb-4 pl-2": isEditing,
                    })}
                >
                    <div
                        className={classNames(
                            "relative grid items-center transition-all rounded-md",
                            {
                                "cursor-pointer hover:bg-white/5":
                                    !!onClick &&
                                    stageEditorContext.currentElement?.id !==
                                        element.id,
                                "bg-white/10":
                                    stageEditorContext.currentElement?.id ===
                                    element.id,
                                "opacity-40": isDragging,
                                "p-2": isEditor,
                            }
                        )}
                        onClick={() => !!onClick && onClick(element)}
                        {...props}
                    >
                        {isEditing && (
                            <div className="flex mb-2 items-center">
                                <span className="grow font-bold text-tertiary">
                                    {t(elementConfig.label)} #{element.id}
                                </span>
                                <Popover
                                    wrapperClassName="inline-block"
                                    button={
                                        <IconButton
                                            icon={faEllipsis}
                                            variant="secondary"
                                            size="medium"
                                        />
                                    }
                                    className="space-y-2"
                                >
                                    {hasSettingsArguments && (
                                        <Button
                                            variant="secondary"
                                            size="small"
                                            className="w-full px-3 py-2"
                                            wrapperClassName="w-full"
                                            rightIcon={faCog}
                                            grow
                                            onClick={() => {
                                                dispatch({
                                                    type: "SET_CURRENT_ELEMENT",
                                                    payload: {
                                                        ...elementConfig,
                                                        arguments:
                                                            settingsArguments,
                                                        title: t(
                                                            elementConfig.label
                                                        ),
                                                        value,
                                                    },
                                                });
                                            }}
                                        >
                                            {t("button.settings")}
                                        </Button>
                                    )}
                                    {renderAddButtons}
                                    <Button
                                        variant="destructive"
                                        size="small"
                                        className="w-full px-3 py-2"
                                        wrapperClassName="w-full"
                                        rightIcon={faTrash}
                                        grow
                                        onClick={handleDelete}
                                    >
                                        {t("button.delete")}
                                    </Button>
                                </Popover>
                            </div>
                        )}
                        <ElementComponent
                            {...(element.props || {})}
                            isEditing={isEditing}
                        />
                    </div>
                </div>
            </div>
            <QuestionDialog />
        </StageElementContextProvider>
    );
};
