import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export const CircleProgress = ({ width, height, value, color, ...props }) => {
    return (
        <div style={{ width, height }}>
            <CircularProgressbar
                strokeWidth={20}
                styles={buildStyles({
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: "round",

                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,

                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',

                    // Colors
                    pathColor: color || `rgba(255, 255, 255, 0.8)`,
                    trailColor: "rgba(255, 255, 255, 0.2)",
                    backgroundColor: "#3e98c7",
                })}
                value={value}
                {...props}
            />
        </div>
    );
};
