import classNames from "classnames";
import { Emoji } from "./Emoji";
import { useState } from "react";

export const EmojiButton = ({
    active,
    className,
    onClick,
    emoji,
    ...props
}) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            className={classNames(
                "text-4xl active:!scale-90 transition-all",
                {
                    "grayscale scale-95": !active,
                    "grayscale": !active,
                },
                className
            )}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={onClick}
            {...props}
        >
            <Emoji
                emoji={emoji}
                play={isHovered || active}
                className="max-w-20 mx-auto"
            />
        </button>
    );
};
