import { useRef, useState } from "react";
import { Button } from "./Button";
import { useTranslation } from "react-i18next";
import imageCompression from "browser-image-compression";
import { CircleProgress } from "./CircleProgress";

export const ImageInput = ({
    value,
    onChange,
    accept = ["image/png", "image/jpeg", "image/svg+xml"],
    className,
    uploadProgress,
    compressionOptions,
    onRemove,
    label,
    children,
}) => {
    const { t } = useTranslation();

    const [compressionProgress, setCompressionProgress] = useState(0);

    const inputRef = useRef();

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    const handleChange = (e) => {
        let file = e.target.files[0];

        if (!file) {
            return;
        }

        const fr = new FileReader();

        fr.readAsDataURL(file);

        fr.onload = async ({ target }) => {
            if (compressionOptions) {
                try {
                    file = await imageCompression(file, {
                        ...compressionOptions,
                        onProgress: (v) => setCompressionProgress(v),
                    });

                    console.log(
                        `compressedFile size ${file.size / 1024 / 1024} MB`
                    ); // smaller than maxSizeMB
                } catch (error) {
                    console.log(error);

                    return;
                }
            }

            onChange({ blob: target.result, file });
        };
    };

    return (
        <div className={className}>
            {!!label && (
                <label className="block mb-1 text-primary text-opacity-90 font-semibold">
                    {label}
                </label>
            )}
            {!!value?.blob && (
                <div className="space-y-2">
                    <div className="relative rounded-xl overflow-hidden">
                        {children}
                        {uploadProgress > 0 && uploadProgress < 100 && (
                            <div className="absolute w-full h-full left-0 top-0 flex items-center justify-center bg-black/40">
                                <CircleProgress
                                    value={uploadProgress}
                                    width={20}
                                    height={20}
                                    color="#1CF683"
                                />
                            </div>
                        )}
                        {compressionProgress > 0 &&
                            compressionProgress < 100 && (
                                <div className="absolute w-full h-full left-0 top-0 flex items-center justify-center bg-black/40">
                                    <CircleProgress
                                        value={compressionProgress}
                                        width={20}
                                        height={20}
                                    />
                                </div>
                            )}
                        <div
                            className="absolute w-full h-full left-0 top-0 bg-black/40 cursor-pointer opacity-0 active:opacity-100 sm:hover:opacity-100 flex items-center justify-center"
                            onClick={handleClick}
                        >
                            <span className="text-center text-base/4">
                                {t("button.choose_image")}
                            </span>
                        </div>
                    </div>
                    {value.blob !== "/placeholder-image.png" && !!onRemove && (
                        <Button
                            variant="secondary"
                            className="w-full"
                            wrapperClassName="w-full"
                            size="small"
                            onClick={() => {
                                if (onRemove) {
                                    onRemove({
                                        blob: "/placeholder-image.png",
                                        file: null,
                                    });
                                }
                            }}
                        >
                            {t("button.remove")}
                        </Button>
                    )}
                </div>
            )}
            {!value?.blob && (
                <Button
                    variant="secondary"
                    className="w-full h-[124px]"
                    wrapperClassName="w-full"
                    onClick={handleClick}
                >
                    {t("button.choose_image")}
                </Button>
            )}
            <input
                type="file"
                accept={accept.join(", ")}
                ref={inputRef}
                onChange={handleChange}
                className="hidden"
            />
        </div>
    );
};
