import { Button } from "../../Button";
import {
    useElementConfig,
    useStageElementEditState,
} from "../../context/StageElementContext";
import { AddMVPElementButton } from "../controls/MVP";
import { StageElementGroupEditWrapper } from "../StageElementGroupEditWrapper";

export const Links = ({ links }) => {
    const isEditing = useStageElementEditState();
    const elementConfig = useElementConfig();

    const renderLinks = (links || []).map((v, index) => {
        return (
            <StageElementGroupEditWrapper
                key={`stage-links-item-${v.buttonUrl}-${index}`}
                className="w-full"
                title="title.link"
                mvpIndex={index}
                mvpKey="links"
                args={elementConfig?.arguments?.links?.arguments}
            >
                <Button
                    variant="secondary"
                    wrapperClassName="block w-full"
                    className="w-full"
                    truncate
                    buttonText={v.buttonText}
                    link={v.link}
                    target="blank"
                />
            </StageElementGroupEditWrapper>
        );
    });

    return (
        <div className="grow space-y-2">
            {renderLinks}
            {isEditing && (
                <AddMVPElementButton
                    wrapperClassName="block w-full"
                    mvpKey="links"
                />
            )}
        </div>
    );
};
