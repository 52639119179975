import { Button } from "./Button";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import classNames from "classnames";

export const Dialog = ({
    children,
    className,
    open,
    onClose,
    title,
    size = "small",
    buttons,
    leftButtons,
    ...props
}) => {
    if (!open) {
        return null;
    }

    return (
        <div className="fixed w-full h-full top-0 left-0 z-50">
            <div
                className="absolute w-full h-full bg-black/80"
                onClick={onClose}
            />
            <div className="absolute left-1/2 -translate-x-1/2 top-1/2 transform -translate-y-1/2 h-full p-4 flex items-center w-full max-w-screen-md pointer-events-none z-50">
                <div
                    className={classNames(
                        "bg-black w-full rounded-lg drop-shadow-2xl max-h-full flex flex-col pointer-events-auto",
                        {
                            "sm:w-96": size === "small",
                            "md:w-[500px]": size === "medium",
                            "xl:w-[750px]": size === "big",
                        }
                    )}
                >
                    {(!!title || !!onClose) && (
                        <div className="flex border-b border-card-border p-4 text-lg font-bold items-center relative z-50">
                            {!!title && (
                                <h3 className="grow text-base">{title}</h3>
                            )}
                            {!!onClose && (
                                <Button
                                    className="bg-transparent text-black !p-1 !px-3"
                                    variant="tertiary"
                                    onClick={onClose}
                                >
                                    <Icon
                                        icon={faClose}
                                        className="text-inherit"
                                    />
                                </Button>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames("px-4 pt-4 overflow-y-auto", {
                            "pb-4": !buttons,
                        })}
                    >
                        {children}
                    </div>
                    {(!!buttons || !!leftButtons) && (
                        <div className="p-4 flex justify-end space-x-2 relative z-30">
                            {!!leftButtons && (
                                <div className="grow">{leftButtons}</div>
                            )}
                            <div className="shrink-0">{buttons}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
