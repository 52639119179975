import { useState } from "react";
import { Textbox } from "../Textbox";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { Tab } from "../Tabs";
import { campaignUtils } from "../../utils/campaignUtils";
import { Textarea } from "../Textarea";
import { Message } from "../Message";

export const CampaignWebsiteTab = ({
    onUpdate,
    campaign,
    onPreview,
    ...props
}) => {
    const { t } = useTranslation();

    const [websiteUrl, setWebsiteUrl] = useState(campaign?.websiteUrl);
    const [description, setDescription] = useState(campaign?.description);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsLoading(true);

        const updatePayload = {
            websiteUrl,
            description,
        };

        try {
            const updateResponse = await campaignUtils.update(
                campaign?.uuid,
                updatePayload
            );

            if (updateResponse.status === 200) {
                onUpdate(updateResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const validate = () => {
        if (
            campaign?.websiteUrl === websiteUrl &&
            campaign?.description === description
        ) {
            return false;
        }

        return true;
    };

    return (
        <Tab {...props}>
            <form onSubmit={handleSubmit}>
                <p className="text-secondary mb-4">
                    {t("description.campaign_website")}
                </p>
                <Textbox
                    type="url"
                    label={t("label.website_url")}
                    value={websiteUrl}
                    onChange={(e) => setWebsiteUrl(e.target.value)}
                    maxLength={255}
                    className="mb-2"
                    button={
                        !!websiteUrl && (
                            <Button
                                variant="secondary"
                                onClick={() => setWebsiteUrl("")}
                            >
                                {t("button.remove")}
                            </Button>
                        )
                    }
                />
                <Message variant="neutral" className="mb-4">
                    {t("text.ad_website_info")}
                </Message>
                <Textarea
                    label={t("label.description")}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={240}
                    className="mb-4"
                />
                <div className="flex justify-end">
                    <Button
                        className="mr-2"
                        variant="secondary"
                        onClick={onPreview}
                        disabled={!campaign?.websiteUrl}
                    >
                        {t("button.preview")}
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                        disabled={!validate()}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </form>
        </Tab>
    );
};
