import { useContext } from "react";
import {
    StageElementContext,
    useElementConfig,
    useElementValue,
} from "../context/StageElementContext";
import { useTranslation } from "react-i18next";
import { Message } from "../Message";
import classNames from "classnames";
import { Circle } from "../Circle";
import { Icon } from "../Icon";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { StageEditorContext } from "./StageEditorContext";

export const StageElementGroupEditWrapper = ({
    children,
    args,
    title,
    mvpIndex,
    mvpKey,
    className,
    ...props
}) => {
    const [stageElementContext] = useContext(StageElementContext);
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementConfig = useElementConfig();
    const elementValue = useElementValue();
    const { t } = useTranslation();

    if (!elementConfig) {
        console.error(`Element config not found`);

        return null;
    }

    if (!Object.keys(args || {}).length) {
        return (
            <Message
                className="!p-2"
                variant="negative"
            >{`No arguments for group given`}</Message>
        );
    }

    const handleFooterMenuOpen = (e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: {
                ...elementConfig,
                arguments: args,
                title,
                value: elementValue,
                mvpIndex,
                mvpKey,
                children,
            },
        });
    };

    return (
        <>
            <div
                {...props}
                className={classNames(
                    "outline-0 relative",
                    {
                        "!cursor-pointer": stageElementContext.isEditor,
                        "min-h-6": stageElementContext.isEditing,
                    },
                    className
                )}
                onClick={(e) =>
                    stageElementContext.isEditor && handleFooterMenuOpen(e)
                }
            >
                {stageElementContext.isEditing && (
                    <div className="absolute -top-2 -right-1 z-10">
                        <Circle className="bg-black outline outline-black w-4 h-4 transition-all">
                            <Icon
                                icon={faCog}
                                size="2xs"
                                className="!text-cash"
                            />
                        </Circle>
                    </div>
                )}
                {children}
            </div>
        </>
    );
};
