import { Dialog } from "../Dialog";
import { useTranslation } from "react-i18next";
import { StageRenderer } from "../StageEditor/StageRenderer";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";
import { Card } from "../Card";
import { evaluateStageTokens } from "lib/src/stage/elements";
import { USER_STAGE_SHARE_MULTIPLIER } from "lib/src/stage/stages";
import { Button } from "../Button";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { raffleUtils } from "../../utils/raffleUtils";
import { UserContext } from "../context/UserContext";

export const StageDialog = ({
    onCancel,
    campaign,
    value,
    open,
    onClaim,
    isPreview,
    ad,
    ...props
}) => {
    const { t } = useTranslation();
    const [userContext, dispatch] = useContext(UserContext);
    const [applicationContext, applicationDispatch] = useContext(UserContext);

    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [timestamps, setTimestamps] = useState([]);
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    useEffect(() => {
        if (timestamps.length >= campaign?.stageDuration || !open) {
            return;
        }

        let secondsElapsedInterval;

        secondsElapsedInterval = setInterval(() => {
            setTimestamps([...timestamps, new Date().getTime()]);

            setSecondsElapsed((prevState) => prevState + 1);
        }, 1000);

        return () => clearInterval(secondsElapsedInterval);
    }, [timestamps, campaign?.stageDuration, open]);

    const validate = () => {
        if (secondsElapsed < campaign?.stageDuration) {
            return false;
        }

        return true;
    };

    const init = () => {
        setSecondsElapsed(0);
        setTimestamps([]);
    };

    if (!campaign || !value?.length) {
        return null;
    }

    const handleClaim = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!ad) {
            return;
        }

        if (isPreview && !!onClaim) {
            onClaim();
            return;
        }

        setIsLoadingClaim(true);

        try {
            const claimResponse = await raffleUtils.claimStageTickets(
                ad?.raffle?.uuid,
                {
                    adKey: ad.adKey,
                }
            );

            const tickets = evaluateStageTokens(
                campaign.stageJson,
                campaign.stageDuration,
                USER_STAGE_SHARE_MULTIPLIER
            );

            if (claimResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        tickets:
                            Number(userContext.user.tickets || 0) + tickets,
                    },
                });

                const updatedRaffle = claimResponse.data.data.raffle;
                const updatedRaffleUser = claimResponse.data.data;

                applicationDispatch({
                    type: "UPDATE_RAFFLE_WITH_USER",
                    payload: {
                        raffle: updatedRaffle,
                        raffleUser: updatedRaffleUser,
                    },
                });

                onClaim(claimResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingClaim(false);
            init();
        }

        setIsLoadingClaim(false);
        init();
    };

    const handleCancel = () => {
        init();

        if (onCancel) {
            onCancel();
        }
    };

    const tickets = evaluateStageTokens(
        campaign.stageJson,
        campaign.stageDuration,
        USER_STAGE_SHARE_MULTIPLIER
    );

    return (
        <Dialog
            cardClassName="mx-auto max-w-screen-md"
            title={campaign.advertiser.name}
            size="big"
            leftButtons={
                <Button variant="tertiary" onClick={handleCancel}>
                    {t("button.cancel")}
                </Button>
            }
            buttons={
                <Button
                    rightIcon={faTicket}
                    disabled={!validate()}
                    onClick={handleClaim}
                    isLoading={isLoadingClaim}
                    progress={
                        secondsElapsed < campaign?.stageDuration &&
                        Math.ceil(
                            (secondsElapsed / campaign?.stageDuration) * 100
                        )
                    }
                    variant="yellow"
                >
                    {t("button.claim_x", { x: tickets })}
                </Button>
            }
            open={open}
            {...props}
        >
            <StageEditorContextProvider>
                <Card className="static">
                    <StageRenderer campaign={campaign} value={value} />
                </Card>
            </StageEditorContextProvider>
        </Dialog>
    );
};
