import { Button } from "../../Button";
import { Headline } from "./Headline";
import { useElementConfig } from "../../context/StageElementContext";

import classNames from "classnames";
import { StageElementTextEditWrapper } from "../StageElementTextEditWrapper";
import { StageElementGroupEditWrapper } from "../StageElementGroupEditWrapper";
import { StageElementImageEditWrapper } from "../StageElementImageEditWrapper";

export const Hero = ({
    title,
    titleSize = "extraLarge",
    text,
    textAlign = "left",
    image,
    imagePosition = "right",
    buttonText,
    buttonLink,
    buttonAlign,
}) => {
    const elementConfig = useElementConfig();

    return (
        <div className="relative grid grid-cols-8 gap-4 lg:gap-6 items-center overflow-hidden">
            <div
                className={classNames("col-span-5", {
                    "order-2": imagePosition === "left",
                    "order-1": imagePosition === "right",
                    "absolute col-span-8 z-10 px-8 py-4 w-full pointer-events-none rounded-xl":
                        imagePosition === "background",
                })}
            >
                <div
                    className={classNames("flex flex-col pointer-events-auto", {
                        "shadow-black drop-shadow-lg":
                            imagePosition === "background",
                    })}
                >
                    <Headline
                        className={classNames("sm:w-3/4", {
                            "text-left": textAlign === "left",
                            "text-right ml-auto": textAlign === "right",
                            "text-center mx-auto": textAlign === "center",
                            "!w-full": imagePosition === "background",
                        })}
                        size={titleSize}
                        title={title}
                    />
                    <StageElementTextEditWrapper
                        argKey="text"
                        className={classNames("sm:w-3/4 text-secondary", {
                            "text-left": textAlign === "left",
                            "text-right ml-auto": textAlign === "right",
                            "text-center mx-auto": textAlign === "center",
                            "!w-full": imagePosition === "background",
                        })}
                    >
                        {!!text && <pre>{text}</pre>}
                    </StageElementTextEditWrapper>
                    {!!buttonText && !!buttonLink && (
                        <div
                            className={classNames("mt-4", {
                                "self-start": buttonAlign === "left",
                                "self-end": buttonAlign === "right",
                                "self-center": buttonAlign === "center",
                            })}
                        >
                            <StageElementGroupEditWrapper
                                title="title.button"
                                args={{
                                    buttonText:
                                        elementConfig?.arguments?.buttonText,
                                    buttonLink:
                                        elementConfig?.arguments?.buttonLink,
                                }}
                            >
                                <Button
                                    buttonText={buttonText}
                                    link={buttonLink}
                                    linkTarget="_blank"
                                />
                            </StageElementGroupEditWrapper>
                        </div>
                    )}
                </div>
            </div>
            {!!image && (
                <StageElementImageEditWrapper
                    className={
                        ("relative",
                        {
                            "order-1": imagePosition === "left",
                            "order-2": imagePosition === "right",
                            "col-span-3": imagePosition !== "background",
                            "col-span-8": imagePosition === "background",
                        })
                    }
                    argKey="image"
                >
                    <img
                        src={image.blob || image}
                        alt="Hero image"
                        className={classNames("w-full rounded-lg", {
                            "brightness-[40%] opacity-70":
                                imagePosition === "background",
                        })}
                    />
                </StageElementImageEditWrapper>
            )}
        </div>
    );
};
