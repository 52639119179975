import { Button } from "./Button";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import classNames from "classnames";

export const FooterMenu = ({
    children,
    className,
    open,
    onClose,
    title,
    fullHeight,
    menuClassName,
    backgroundClassName,
    buttons,
    ...props
}) => {
    return (
        <div
            className={classNames(
                "fixed w-full h-full top-0 left-0 z-[2000] pointer-events-none transition-all !transform-none",
                {
                    "opacity-100  ": open,
                    "opacity-0 invisible": !open,
                }
            )}
        >
            <div
                className={classNames(
                    "absolute w-full h-full bg-black/80 pointer-events-auto",
                    {
                        "hidden": !open,
                    },
                    backgroundClassName
                )}
                onClick={onClose}
            />
            <div className="fixed left-0 right-0 bottom-0 w-full h-full sm:w-auto pointer-events-none z-50 transition-all">
                <div
                    className={classNames(
                        "absolute bottom-0 left-0 right-0 bg-black max-h-[80%] rounded-t-3xl drop-shadow-2xl flex flex-col pointer-events-auto max-w-screen-sm mx-auto",
                        {
                            "translate-y-full": !open,
                            "translate-y-0": open,
                            "!h-full": fullHeight,
                        },
                        menuClassName
                    )}
                >
                    {(!!title || !!onClose) && (
                        <div className="flex border-b border-card-border p-4 text-lg font-bold items-center">
                            {!!title && (
                                <h3 className="grow text-base">{title}</h3>
                            )}
                            {!!onClose && (
                                <Button
                                    className="bg-transparent text-black !p-1 !px-3"
                                    variant="tertiary"
                                    onClick={onClose}
                                >
                                    <Icon
                                        icon={faClose}
                                        className="text-inherit"
                                    />
                                </Button>
                            )}
                        </div>
                    )}
                    <div
                        className={classNames("p-4 grow overflow-y-auto", {
                            "!pb-0": !!buttons,
                        })}
                    >
                        {children}
                    </div>
                    {!!buttons && (
                        <div className="text-right space-x-4 p-4">
                            {buttons}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
