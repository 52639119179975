import classNames from "classnames";
import { AdvertiserLogo } from "./AdvertiserLogo";
import { Label } from "./Label";

export const Advertiser = ({ advertiser, label, className, ...props }) => {
    return (
        <div className={classNames(className)} {...props}>
            {!!label && <Label className="block mb-2">{label}</Label>}
            <AdvertiserLogo
                advertiser={advertiser}
                size="large"
                className="rounded-lg"
            />
        </div>
    );
};
