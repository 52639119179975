import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import { StageEditorControls } from "../StageEditorControls";
import { Card } from "../../Card";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useContext } from "react";
import { StageEditorContext } from "../StageEditorContext";
import {
    useElementConfig,
    useElementValue,
} from "../../context/StageElementContext";

export const MVP = ({ value = [], onChange, argConfig, ...props }) => {
    const { t } = useTranslation();

    if (!value || !value.length) {
        value = [argConfig.defaultValue || {}];
    }

    const handleChange = (key, val, index) => {
        onChange(
            value.map((v, i) => {
                if (i === index) {
                    return {
                        ...v,
                        [key]: val,
                    };
                }

                return v;
            })
        );
    };

    const handleAdd = () => {
        if (!!argConfig.maxEntries && value.length >= argConfig.maxEntries) {
            return;
        }

        const nextId =
            value?.length > 0 ? Math.max(...value.map((v) => v.id)) + 1 : 0;

        let newItem = {
            ...(argConfig.defaultValue || {}),
            id: nextId,
        };

        onChange([...value, newItem]);
    };

    const renderValues = value.map((v, index) => {
        return (
            <Card key={`${argConfig.label}-mvp-${index}`} className="!p-2">
                <StageEditorControls
                    value={v}
                    onChange={(key, val) => handleChange(key, val, index)}
                    elementConfig={argConfig}
                    index={index}
                />
            </Card>
        );
    });

    return (
        <div className="space-y-2">
            <div className="space-y-2">{renderValues}</div>
            <Button
                variant="secondary"
                className="w-full"
                onClick={handleAdd}
                disabled={
                    !!argConfig.maxEntries &&
                    value.length >= argConfig.maxEntries
                }
            >
                {t("button.add_element")}
            </Button>
        </div>
    );
};

export const AddMVPElementButton = ({
    className,
    mvpKey,
    elementConfig: elementConfigProp,
    ...props
}) => {
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementValue = useElementValue();
    let elementConfig = useElementConfig();

    if (elementConfigProp) {
        elementConfig = elementConfigProp;
    }

    const { t } = useTranslation();

    const handleAdd = () => {
        let updatedValue = elementValue;

        const nextId =
            elementValue.props[mvpKey]?.length > 0
                ? Math.max(...elementValue.props[mvpKey].map((v) => v.id)) + 1
                : 0;

        let newElement = {
            ...(elementConfig.arguments[mvpKey].defaultValue || {}),
            id: nextId,
        };

        updatedValue.props[mvpKey].push(newElement);

        dispatch({ type: "UPDATE_ELEMENT", payload: updatedValue });
    };

    if (
        elementValue.props[mvpKey]?.length >=
        elementConfig.arguments[mvpKey].maxEntries
    ) {
        return null;
    }

    return (
        <Button
            iconProps={{ className: "!text-secondary" }}
            variant="create"
            className={classNames("w-full", className)}
            rightIcon={faPlus}
            onClick={handleAdd}
            {...props}
        >
            {t("button.add")}
        </Button>
    );
};
