import classNames from "classnames";
import { Icon } from "./Icon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { LogoLoader } from "./LogoLoader";
import { CircleProgress } from "./CircleProgress";
import { Badge } from "./Badge";

export const Button = ({
    className,
    children,
    buttonText,
    onClick,
    type = "button",
    variant = "primary",
    disabled,
    isLoading,
    icon,
    iconProps,
    iconClassName,
    wrapperClassName,
    rightIcon,
    size = "medium",
    subtitle,
    progress,
    grow,
    badge,
    badgeProps,
    truncate,
    link,
    linkTarget,
    ...props
}) => {
    if (isLoading) {
        disabled = true;
    }

    const Wrapper = link ? "a" : "span";

    return (
        <Wrapper className={classNames("inline-block", wrapperClassName)}>
            <button
                type={type}
                className={classNames(
                    "block font-semibold transition",
                    {
                        "bg-primary sm:hover:bg-cash active:bg-cash text-black":
                            variant === "primary",
                        "bg-yellow sm:hover:bg-white active:bg-white text-black":
                            variant === "yellow",
                        "border-white border-opacity-10 border bg-white bg-opacity-5 text-white sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-10 active:!bg-opacity-15":
                            variant === "secondary" && !disabled,
                        "border-cash border-opacity-10 border bg-cash bg-opacity-5 text-cash sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                            variant === "secondaryActive",
                        "border-red border-opacity-10 border bg-red bg-opacity-5 text-red sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                            variant === "secondaryNegative",
                        "border-yellow border-opacity-10 border bg-yellow bg-opacity-5 text-yellow sm:hover:border-opacity-20 active:border-opacity-30 sm:hover:bg-opacity-15 active:bg-opacity-20":
                            variant === "secondaryYellow",
                        "!text-secondary hover:!text-primary bg-white bg-opacity-0 sm:hover:bg-opacity-5 active:bg-opacity-10":
                            variant === "tertiar",
                        "!text-secondary border-tertiary border-2 border-dashed hover:!text-primary bg-white bg-opacity-0 sm:hover:bg-opacity-5 active:bg-opacity-10":
                            variant === "create",
                        "bg-red text-white bg-opacity-85 sm:hover:bg-opacity-90 active:bg-opacity-100":
                            variant === "destructive",
                        "text-red": variant === "tertiaryDestructive",
                        "!bg-white !bg-opacity-5 !text-white !text-opacity-40":
                            disabled,
                        "px-3 py-1 rounded-md": size === "small" && !grow,
                        "px-2 rounded-md":
                            (size === "small" && grow) ||
                            (size === "extraSmall" && !grow),
                        "px-4 py-2 rounded-md": size === "medium" && !grow,
                        "p-3 rounded-md": size === "medium" && grow,
                        "px-8 py-4 rounded-xl !font-bold text-md":
                            size === "large" && !grow,
                        "p-4 rounded-xl !font-bold text-md":
                            size === "large" && grow,
                    },
                    className
                )}
                onClick={onClick}
                disabled={disabled}
                {...props}
            >
                {!!badge && (
                    <div className="absolute -top-4 -right-3">
                        <Badge className="text-xs font-bold" {...badgeProps}>
                            {badge}
                        </Badge>
                    </div>
                )}
                <div className="flex items-center justify-center space-x-2">
                    {progress !== undefined &&
                        progress !== false &&
                        progress !== null &&
                        progress < 100 && (
                            <CircleProgress
                                value={progress}
                                width={18}
                                height={18}
                            />
                        )}
                    {!!isLoading && <LogoLoader />}
                    {!!icon &&
                        !isLoading &&
                        (!progress || progress === 100) && (
                            <Icon
                                icon={icon}
                                className={classNames(
                                    {
                                        "!text-black":
                                            variant === "primary" ||
                                            variant === "yellow",
                                        "!text-secondary":
                                            variant === "tertiary",
                                        "!text-yellow":
                                            variant === "secondaryYellow",
                                        "!text-white !text-opacity-40":
                                            disabled,
                                        "py-[5px]": size !== "small",
                                    },
                                    iconClassName
                                )}
                                size={size === "large" ? null : "sm"}
                                {...(iconProps || {})}
                            />
                        )}
                    {(!!children || !!buttonText) && (
                        <span
                            className={classNames("!text-inherit block", {
                                "grow text-left": grow,
                                "truncate max-w-40 md:max-w-56 lg:max-w-96":
                                    truncate,
                            })}
                        >
                            {buttonText || children}
                        </span>
                    )}
                    {!!rightIcon && (
                        <Icon
                            icon={rightIcon}
                            className={classNames("py-[5px]", {
                                "!text-black":
                                    variant === "primary" ||
                                    variant === "yellow",
                                "!text-secondary": variant === "tertiary",
                                "!text-red": variant === "secondaryNegative",
                                "!text-yellow": variant === "secondaryYellow",
                                "!text-white !text-opacity-40": disabled,
                            })}
                            fixedWidth={grow}
                            size={size === "large" ? null : "sm"}
                            {...iconProps}
                        />
                    )}
                </div>
                {!!subtitle && (
                    <p className="text-secondary !font-semibold text-sm">
                        {subtitle}
                    </p>
                )}
            </button>
        </Wrapper>
    );
};
