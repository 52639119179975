import { useEffect, useState } from "react";
import { campaignUtils } from "../utils/campaignUtils";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { Card, FieldContainer } from "./Card";
import { Button } from "./Button";
import { Icon } from "./Icon";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Loader } from "./Loader";
import { LogoLoader } from "./LogoLoader";
import { Tab, Tabs } from "./Tabs";
import { Feature } from "./Feature";
import { NumberFormatter } from "../helpers/number.helper";
import { Chart } from "./Chart";
import { Emoji } from "./Emoji";
import { Link } from "react-router-dom";
import { Message } from "./Message";
import { ReactionsChart } from "./ReactionsChart";
import { CampaignReactions } from "./CampaignReactions";

export const CampaignInsightsTab = ({ campaign, ...props }) => {
    const { t } = useTranslation();

    const initialTab = "chart";

    const [insights, setInsights] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [range, setRange] = useState("month");
    const [currentTab, setCurrentTab] = useState(initialTab);

    useEffect(() => {
        fetchInsights();
    }, [campaign?.uuid, range]);

    const fetchInsights = async () => {
        if (!campaign?.uuid) {
            return;
        }

        setIsLoading(true);

        try {
            const insightResponse = await campaignUtils.getInsights(
                campaign?.uuid,
                range
            );

            if (insightResponse.status === 200) {
                setInsights(insightResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    if (!campaign) {
        return null;
    }

    const data = (insights || []).map((v) => ({
        name: new Date(v.date).toLocaleDateString(
            {},
            {
                day: "2-digit",
                month: "2-digit",
            }
        ),
        clicks: v.clicks,
        views: v.views,
        impressions: v.impressions,
        stageViews: v.stageViews,
    }));

    return (
        <Tab {...props}>
            <FieldContainer className="mb-4 md:grid-cols-4">
                <Feature label={t("label.impressions")}>
                    {NumberFormatter.format(campaign.impressions)}
                </Feature>
                <Feature label={t("label.views")} childrenClassName="text-blue">
                    {NumberFormatter.format(campaign.views)}
                </Feature>
                <Feature
                    label={t("label.stage_views")}
                    childrenClassName="text-yellow"
                >
                    {NumberFormatter.format(campaign.stageViews)}
                </Feature>
                <Feature
                    label={t("label.clicks")}
                    childrenClassName="text-cash"
                >
                    {NumberFormatter.format(campaign.clicks)}
                </Feature>
            </FieldContainer>
            <CampaignReactions campaign={campaign} className="mb-4" />
            <Tabs
                currentTab={currentTab}
                onTabChange={setCurrentTab}
                forceDesktop
                initialTab={initialTab}
            >
                <Tab tabId="chart" title={t("title.chart")}>
                    <div className="flex items-center mb-2">
                        <div className="grow">
                            {isLoading && <LogoLoader />}
                        </div>
                        <div>
                            <Button
                                size="small"
                                variant={
                                    range === "month" ? "secondary" : "tertiary"
                                }
                                onClick={() => setRange("month")}
                                className="mr-2"
                            >
                                {t("button.month")}
                            </Button>
                            <Button
                                size="small"
                                variant={
                                    range === "week" ? "secondary" : "tertiary"
                                }
                                onClick={() => setRange("week")}
                            >
                                {t("button.week")}
                            </Button>
                        </div>
                    </div>
                    <Chart
                        data={data}
                        filterable
                        areas={[
                            {
                                key: "impressions",
                                title: t("label.impressions"),
                                color: "default",
                            },
                            {
                                key: "views",
                                title: t("label.views"),
                                color: "ar_blue",
                            },
                            {
                                key: "stageViews",
                                title: t("label.stage_views"),
                                color: "ar_yellow",
                            },
                            {
                                key: "clicks",
                                title: t("label.clicks"),
                                color: "cash",
                            },
                        ]}
                    />
                </Tab>
                <Tab tabId="stage" title="Stage"></Tab>
            </Tabs>
        </Tab>
    );
};
