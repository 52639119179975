import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getImageError } from "../dialogs/NewCampaignDialog";
import { campaignUtils } from "../../utils/campaignUtils";
import useQuestion from "../../hooks/useQuestion";
import { ImageInput } from "../ImageInput";
import { useElementValue } from "../context/StageElementContext";

import classNames from "classnames";

export const StageEditorImageUpload = ({
    className,
    onUpload,
    maxSize = 2000000,
    campaign,
    value,
    mvpId,
    argKey,
    question: questionProp,
    questionTitle,
    questionConfirmLabel = "Yes",
    questionCancelLabel = "Cancel",
    questionVariant = "positive",
    children,
    ...props
}) => {
    const { t } = useTranslation();

    const [image, setImage] = useState(null);
    const [isLoadingSignature, setIsLoadingSignature] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { question, QuestionDialog } = useQuestion();
    const elementValue = useElementValue();

    useEffect(() => {
        setImage({ blob: value?.secure_url });
    }, [value]);

    const handleChange = async (v) => {
        if (v.blob && v.file == null) {
            setImage(v);
            onUpload({ secure_url: v.blob });

            return;
        }

        if (!v?.file) {
            console.error("No file given");

            return;
        }

        if (v.file.size > maxSize) {
            console.error("File is too big", v.file.size);

            return;
        }

        if (questionProp) {
            const answer = await question(
                questionTitle,
                questionProp,
                questionConfirmLabel,
                questionCancelLabel,
                questionVariant
            );

            if (!answer) {
                return;
            }
        }

        let signature;

        setIsLoadingSignature(true);

        try {
            const uploadSignatureResponse =
                await campaignUtils.getImageUploadSignature(
                    campaign?.uuid,
                    elementValue.id,
                    argKey,
                    mvpId
                );

            if (uploadSignatureResponse.status === 200) {
                signature = uploadSignatureResponse.data.data;
            }
        } catch (err) {
            console.error(err);

            return;
        }

        setIsLoadingSignature(false);

        if (!signature) {
            return;
        }

        const uniqueUploadId = generateUniqueUploadId();
        const chunkSize = 5 * 1024 * 1024;
        const totalChunks = Math.ceil(v.file.size / chunkSize);

        let currentChunk = 0;

        setIsUploading(true);

        const uploadChunk = async (start, end) => {
            if (!campaign) {
                return;
            }

            let publicId = `${campaign.uuid}-${elementValue.id}-${argKey}`;

            if (mvpId) {
                publicId += `-${mvpId}`;
            }

            publicId += "-preview";

            const formData = new FormData();
            formData.append("file", v.file.slice(start, end));
            formData.append(
                "cloud_name",
                process.env.REACT_APP_CLOUDINARY_SPACE
            );
            formData.append("upload_preset", "campaign_image_upload");
            formData.append(
                "api_key",
                process.env.REACT_APP_CLOUDINARY_API_KEY
            );
            formData.append("timestamp", signature.timestamp);
            formData.append("signature", signature.signature);

            formData.append("public_id", publicId);
            formData.append(
                "context",
                `uuidAdvertiser=${campaign.uuidAdvertiser}|uuidCampaign=${campaign.uuid}`
            );

            const contentRange = `bytes ${start}-${end - 1}/${v.file.size}`;

            console.log(
                `Uploading chunk for uniqueUploadId: ${uniqueUploadId}; start: ${start}, end: ${
                    end - 1
                }`
            );

            try {
                const response = await fetch(
                    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_SPACE}/auto/upload`,
                    {
                        method: "POST",
                        body: formData,
                        headers: {
                            "X-Unique-Upload-Id": uniqueUploadId,
                            "Content-Range": contentRange,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Chunk upload failed.");
                }

                currentChunk++;

                if (currentChunk < totalChunks) {
                    const nextStart = currentChunk * chunkSize;
                    const nextEnd = Math.min(
                        nextStart + chunkSize,
                        v.file.size
                    );
                    uploadChunk(nextStart, nextEnd);

                    setUploadProgress(end / v.file.size);
                } else {
                    setIsUploading(false);
                    setUploadProgress(0);

                    const fetchResponse = await response.json();

                    setImage({ blob: fetchResponse.secure_url });

                    onUpload(fetchResponse);

                    console.info("File upload complete.");
                }
            } catch (error) {
                console.error("Error uploading chunk:", error);
                setIsUploading(false);
                setUploadProgress(0);
            }
        };

        const start = 0;
        const end = Math.min(chunkSize, v.file.size);
        uploadChunk(start, end);
    };

    return (
        <div className={classNames("relative", className)} {...props}>
            <ImageInput
                value={image}
                onChange={handleChange}
                error={!isUploading && getImageError(t, image, maxSize)}
                isLoading={isUploading}
                accept={["image/jpeg", "image/png"]}
                compressionOptions={{
                    maxSizeMB: 1,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                }}
                uploadProgress={uploadProgress || isUploading ? 1 : 0}
            >
                {children}
            </ImageInput>
            <QuestionDialog />
        </div>
    );
};

const generateUniqueUploadId = () => {
    return `uqid-${Date.now()}`;
};
