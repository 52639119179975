import classNames from "classnames";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

export const TinyChart = ({
    data,
    areas,
    className,
    wrapperClassName,
    ...props
}) => {
    return (
        <ResponsiveContainer className={classNames("!h-24", wrapperClassName)}>
            <AreaChart
                data={data}
                className={classNames("relative", className)}
                {...props}
            >
                <defs>
                    <linearGradient id="color_cash" x1="0" y1="0" x2="0" y2="1">
                        <stop
                            offset="0%"
                            stopColor="#1CF683"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="100%"
                            stopColor="#1CF683"
                            stopOpacity={0.1}
                        />
                    </linearGradient>
                    <linearGradient
                        id="color_ar_blue"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                    >
                        <stop
                            offset="0%"
                            stopColor="#3b82f6"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="100%"
                            stopColor="#3b82f6"
                            stopOpacity={0.1}
                        />
                    </linearGradient>
                </defs>
                <Tooltip wrapperClassName="!bg-black !border-card-border rounded-md" />
                <XAxis
                    dataKey="name"
                    fontSize="12px"
                    interval="preserveStartEnd"
                    dy={4}
                />
                {(areas || []).map((area) => (
                    <Area
                        key={`tiny-chart-area-${area.title}`}
                        type="natural"
                        dataKey={area.key}
                        name={area.title}
                        stroke={area.color}
                        fill={`url(#color_${area.color})`}
                        fillOpacity={0.4}
                        animationDuration={500}
                        animationEasing="ease-out"
                    />
                ))}
            </AreaChart>
        </ResponsiveContainer>
    );
};
