const sizeOptions = {
    "large": "label.large",
    "medium": "label.medium",
    "small": "label.small",
};

const extendedSizeOptions = {
    "extraLarge": "label.extra_large",
    "large": "label.large",
    "medium": "label.medium",
    "small": "label.small",
    "extraSmall": "label.extra_small",
};

const reducedAlignOptions = {
    "left": "label.left",
    "right": "label.right",
};

const imagePositionOptions = {
    "left": "label.left",
    "background": "label.background",
    "right": "label.right",
};

const alignOptions = {
    "left": "label.left",
    "center": "label.center",
    "right": "label.right",
};

export const stageStudioElements = {
    "hero": {
        label: "label.hero",
        tokens: 10,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                maxLength: 64,
            },
            titleSize: {
                label: "label.title_size",
                type: "select",
                group: "title.title",
                options: extendedSizeOptions,
                inSettings: true,
            },
            textAlign: {
                label: "label.text_align",
                type: "buttons",
                options: alignOptions,
                inSettings: true,
            },
            text: {
                label: "label.text",
                type: "markdown",
                maxLength: 300,
            },
            image: {
                label: "label.image",
                type: "image",
            },
            imagePosition: {
                label: "label.image_position",
                type: "buttons",
                options: imagePositionOptions,
                inSettings: true,
            },
            buttonText: {
                label: "label.button_text",
                type: "text",
                group: "title.button",
                maxLength: 32,
            },
            buttonLink: {
                label: "label.button_link",
                type: "text",
                group: "title.button",
                maxLength: 300,
            },
            buttonAlign: {
                label: "label.button_align",
                type: "buttons",
                options: alignOptions,
                inSettings: true,
            },
        },
        defaultProps: {
            title: "Your Words, Our Magic",
            text: "Type something, and we’ll make it look fantastic. Ready?",
            textAlign: "left",
            titleSize: "extraLarge",
            buttonText: "Click here",
            buttonLink: "#",
            buttonAlign: "left",
            image: "/placeholder-image.png",
            imagePosition: "right",
        },
    },
    "paragraph": {
        label: "label.paragraph",
        tokens: 5,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                maxLength: 64,
            },
            text: {
                label: "label.text",
                type: "markdown",
                maxLength: 300,
            },
            titleSize: {
                label: "label.title_size",
                type: "select",
                options: extendedSizeOptions,
                inSettings: true,
            },
        },
        defaultProps: {
            text: "Write something here that makes people say, ‘Take my money!",
        },
    },
    "links": {
        label: "label.links",
        arguments: {
            links: {
                label: "label.items",
                type: "mvp",
                maxEntries: 9,
                tokensPerEntry: 2,
                arguments: {
                    buttonText: {
                        label: "label.text",
                        type: "text",
                        maxLength: 64,
                    },
                    link: {
                        label: "label.link",
                        type: "text",
                        maxLength: 300,
                    },
                },
                defaultValue: {
                    buttonText: "Click here",
                    link: "#",
                },
            },
        },
        defaultProps: {
            links: [
                {
                    id: 0,
                    buttonText: "Click here",
                    link: "#",
                },
            ],
        },
    },
    "productSlider": {
        label: "label.product_slider",
        tokens: 5,
        arguments: {
            title: {
                label: "label.title",
                type: "text",
                group: "title.title",
                maxLength: 64,
            },
            titleSize: {
                label: "label.title_size",
                type: "select",
                group: "title.title",
                options: extendedSizeOptions,
                inSettings: true,
            },
            description: {
                label: "label.description",
                type: "markdown",
                group: "title.title",
                maxLength: 300,
            },
            products: {
                label: "label.products",
                type: "mvp",
                maxEntries: 9,
                tokensPerEntry: 5,
                arguments: {
                    title: {
                        label: "label.title",
                        type: "text",
                        maxLength: 64,
                    },
                    description: {
                        label: "label.description",
                        type: "markdown",
                        maxLength: 300,
                    },
                    price: {
                        label: "label.price",
                        type: "text",
                        group: "label.price",
                        maxLength: 24,
                    },
                    discount: {
                        label: "label.discount",
                        type: "text",
                        group: "label.price",
                        maxLength: 24,
                    },
                    image: {
                        label: "label.image",
                        type: "image",
                    },
                    buttonText: {
                        label: "label.button_text",
                        type: "text",
                        group: "title.button",
                        maxLength: 32,
                    },
                    buttonLink: {
                        label: "label.button_link",
                        type: "text",
                        group: "title.button",
                        maxLength: 300,
                    },
                },
                defaultValue: {
                    title: "Product title",
                    description: "Product description",
                    price: "9.99 €",
                    image: "/placeholder-image.png",
                    buttonText: "Click here",
                    buttonLink: "#",
                },
            },
        },
        defaultProps: {
            title: "This is the title",
            titleSize: "medium",
            description: "This is the description",
            products: [
                {
                    id: 0,
                    title: "Product title",
                    description: "Product description",
                    price: "9.99 €",
                    image: "/placeholder-image.png",
                    buttonText: "Click here",
                    buttonLink: "#",
                },
            ],
        },
    },
};

export const evaluateStageTokens = (elements, duration, multiplier) => {
    if (!elements || !elements.length) {
        return null;
    }

    let tokens = 0;

    elements.forEach((v) => (tokens += evaluateTokensForElement(v)));

    if (duration) {
        tokens += evaluateStageDurationTokens(duration);
    }

    if (multiplier) {
        tokens = Math.floor(tokens * multiplier);
    }

    return tokens;
};

export const evaluateVideoDurationTokens = (duration, multiplier) => {
    let tokens = 0;

    if (duration > 30) {
        console.error("Video duration too long");

        return;
    } else if (duration > 20) {
        tokens = 40;
    } else if (duration > 15) {
        tokens = 25;
    } else if (duration > 10) {
        tokens = 20;
    } else if (duration > 5) {
        tokens = 15;
    } else if (duration > 0) {
        tokens = 10;
    }

    if (multiplier) {
        tokens = Math.floor(tokens * multiplier);
    }

    return tokens;
};

export const evaluateStageDurationTokens = (duration, multiplier) => {
    let tokens = 0;

    if (duration > 30) {
        console.error("Video duration too long");

        return;
    } else if (duration > 20) {
        tokens = 50;
    } else if (duration > 15) {
        tokens = 35;
    } else if (duration > 10) {
        tokens = 30;
    } else if (duration > 5) {
        tokens = 25;
    } else if (duration > 0) {
        tokens = 20;
    }

    if (multiplier) {
        tokens = Math.floor(tokens * multiplier);
    }

    return tokens;
};

export const evaluateTokensForElement = (element) => {
    const config = stageStudioElements[element.type];

    if (!config) {
        console.error(`Config for element type ${element.type} not found`);

        return 0;
    }

    let tokens = 0;

    tokens += config.tokens || 0;

    let tokensPerEntry = Object.entries(config.arguments || {}).reduce(
        (prev, [key, entry]) => {
            if (entry.tokensPerEntry > 0) {
                let fieldValue = element.props?.[key] || element[key];

                if (!fieldValue?.length) {
                    return prev;
                }

                return (
                    prev +
                    fieldValue.reduce(
                        (prev) => (prev += entry.tokensPerEntry),
                        0
                    )
                );
            }

            return prev;
        },
        0
    );

    tokens += tokensPerEntry;

    return tokens;
};

export const getElementConfigFromType = (type) => {
    let config = stageStudioElements[type];

    if (!config) {
        console.error(`Element config for type ${type} not found`);

        return undefined;
    }

    return config;
};
