import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Tab } from "../Tabs";
import { StageEditor } from "../StageEditor/StageEditor";
import { StageEditorContextProvider } from "../StageEditor/StageEditorContext";
import { evaluateStageTokens } from "lib/src/stage/elements";
import { StageDialog } from "../dialogs/StageDialog";
import { Button } from "../Button";
import { campaignUtils } from "../../utils/campaignUtils";
import { Label } from "../Label";
import { faEye, faPencil, faTimes } from "@fortawesome/free-solid-svg-icons";
import useQuestion from "../../hooks/useQuestion";
import { Buttons } from "../Buttons";

export const StageStudioTab = ({ campaign, onUpdate, ...props }) => {
    const { t } = useTranslation();
    const { question, QuestionDialog } = useQuestion();

    const [userContext, dispatch] = useContext(UserContext);

    const [stageConfig, setStageConfig] = useState(campaign?.stageJson);
    const [duration, setDuration] = useState(campaign?.stageDuration || 15);

    useEffect(() => {
        if (!campaign?.stageJson) {
            return;
        }

        setStageConfig(campaign.stageJson || []);
    }, [campaign?.stageJson]);

    const tokens = evaluateStageTokens(stageConfig, duration);

    return (
        <Tab {...props}>
            <StageEditorContextProvider
                value={stageConfig}
                onChange={(v) => setStageConfig(v)}
                campaign={campaign}
            >
                <div className="space-y-4">
                    <StageEditor
                        value={stageConfig || []}
                        campaign={campaign}
                        onChange={(v) => {
                            setStageConfig(v);
                        }}
                        duration={duration}
                        onUpdate={onUpdate}
                    />
                    <div>
                        <Label className="block mb-2">
                            {t("label.duration")}
                        </Label>
                        <Buttons
                            value={duration}
                            onChange={(v) => setDuration(v)}
                            options={[
                                {
                                    label: t("button.seconds", {
                                        value: 5,
                                    }),
                                    value: 5,
                                    subtitle: t("text.tokens_per_view", {
                                        value: 10,
                                    }),
                                },
                                {
                                    label: t("button.seconds", {
                                        value: 10,
                                    }),
                                    value: 10,
                                    subtitle: t("text.tokens_per_view", {
                                        value: 15,
                                    }),
                                },
                                {
                                    label: t("button.seconds", {
                                        value: 15,
                                    }),
                                    value: 15,
                                    subtitle: t("text.tokens_per_view", {
                                        value: 20,
                                    }),
                                },
                                {
                                    label: t("button.seconds", {
                                        value: 20,
                                    }),
                                    value: 20,
                                    subtitle: t("text.tokens_per_view", {
                                        value: 25,
                                    }),
                                },
                                {
                                    label: t("button.seconds", {
                                        value: 30,
                                    }),
                                    value: 30,
                                    subtitle: t("text.tokens_per_view", {
                                        value: 40,
                                    }),
                                },
                            ]}
                        />
                    </div>
                    <div className="text-right">
                        {tokens > 0 && (
                            <span className="text-tertiary mr-4">
                                {t("text.tokens_per_view", {
                                    value: tokens,
                                })}
                            </span>
                        )}
                    </div>
                </div>
            </StageEditorContextProvider>
            <QuestionDialog />
        </Tab>
    );
};
