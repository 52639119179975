import { useContext, useEffect, useState } from "react";
import { Page } from "../Page";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import { useNavigate } from "react-router-dom";
import { HelpNavigator } from "../navigators/AdvertisementsNavigator";
import { campaignUtils } from "../../utils/campaignUtils";
import { Card } from "../Card";
import { AdvertiserUpdateForm } from "../forms/AdvertiserUpdateForm";
import { Tab, Tabs } from "../Tabs";
import { CampaignList } from "../lists/CampaignList";
import {
    faAd,
    faPlus,
    faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Heading } from "../Heading";

export const AdvertisementsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [userContext, dispatch] = useContext(UserContext);

    const [isLoadingCreate, setIsLoadingCreate] = useState(false);
    const [currentTab, setCurrentTab] = useState("campaigns");

    useEffect(() => {
        if (!userContext?.user?.advertiser?.uuid) {
            return;
        }
    }, [userContext?.user]);

    const handleCreateCampaign = async () => {
        setIsLoadingCreate(true);

        try {
            const createResponse = await campaignUtils.create({});

            if (createResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        advertiser: {
                            ...userContext.user?.advertiser,
                            campaignCount:
                                (userContext.user?.advertiser?.campaignCount ||
                                    0) + 1,
                        },
                    },
                });

                navigate(
                    `/play/account/campaigns/${createResponse.data.data.uuid}`
                );
            }

            setIsLoadingCreate(false);
        } catch (err) {
            console.error(err);
            setIsLoadingCreate(false);
        }
    };

    if (!userContext?.user?.advertiser?.uuid) {
        return null;
    }

    return (
        <Page
            title={t("title.advertisement_center")}
            subtitle={t("subtitle.advertisements")}
        >
            <div className="grid lg:grid-cols-12 gap-8 sm:gap-4 mb-8">
                <Card className="lg:col-span-4 h-full">
                    <AdvertiserUpdateForm />
                </Card>
                <div className="lg:col-span-8">
                    <Heading
                        title={t("title.your_campaigns")}
                        rightContent={
                            <Button
                                onClick={handleCreateCampaign}
                                disabled={
                                    userContext?.user?.advertiser
                                        ?.campaignCount >= 5
                                }
                                isLoading={isLoadingCreate}
                                rightIcon={faPlus}
                            >
                                {t("button.new_campaign")}
                            </Button>
                        }
                    />
                    <CampaignList
                        sort="campaign.status"
                        sortDirection="descending"
                        uuidAdvertiser={userContext?.user?.advertiser?.uuid}
                    />
                </div>
            </div>
            <HelpNavigator />
        </Page>
    );
};
