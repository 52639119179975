import apiHelper from "../helpers/api.helper";

export const campaignUtils = {
    get: async (uuid) => {
        return await apiHelper.doApiCall("GET", `/campaigns/${uuid}`);
    },
    delete: async (uuid) => {
        return await apiHelper.doApiCall("DELETE", `/campaigns/${uuid}`);
    },
    search: async ({ filters, sort, sortDirection }) => {
        return await apiHelper.doApiCall("GET", `/campaigns`, {
            params: {
                filters: JSON.stringify(filters || []),
                sort,
                sortDirection,
            },
        });
    },
    update: async (
        uuid,
        {
            websiteUrl,
            interests,
            description,
            assetId,
            limit,
            websiteDuration,
            stageJson,
            stageDuration,
        },
        options = {}
    ) => {
        const formData = new FormData();

        if (websiteUrl !== undefined && websiteUrl !== null) {
            formData.append("websiteUrl", websiteUrl);
        }

        if (description !== undefined && description !== null) {
            formData.append("description", description);
        }

        if (interests !== undefined && interests !== null) {
            formData.append("interests", interests);
        }

        if (assetId !== undefined && assetId !== null) {
            formData.append("assetId", assetId);
        }

        if (limit !== undefined && limit !== null) {
            formData.append("limit", limit);
        }

        if (websiteDuration !== undefined && websiteDuration !== null) {
            formData.append("websiteDuration", websiteDuration);
        }

        if (stageJson !== undefined && stageJson !== null) {
            formData.append("stageJson", stageJson);
        }

        if (stageDuration !== undefined && stageDuration !== null) {
            formData.append("stageDuration", stageDuration);
        }

        return await apiHelper.doApiCall("PUT", `/campaigns/${uuid}`, {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 1000 * 120,
            ...options,
        });
    },
    create: async (
        { websiteUrl, video, interests, description },
        options = {}
    ) => {
        const formData = new FormData();

        if (websiteUrl !== undefined) {
            formData.append("websiteUrl", websiteUrl);
        }

        if (description !== undefined) {
            formData.append("description", description);
        }

        if (interests !== undefined) {
            formData.append("interests", interests);
        }

        if (video !== undefined) {
            formData.append("video", video);
        }

        return await apiHelper.doApiCall("POST", `/campaigns`, {
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
            timeout: 1000 * 120,
            ...options,
        });
    },
    getInsights: async (uuid, range = "week") => {
        return await apiHelper.doApiCall(
            "GET",
            `/campaigns/${uuid}/insights?range=${range}`
        );
    },
    publish: async (uuid) => {
        return await apiHelper.doApiCall("POST", `/campaigns/${uuid}/publish`);
    },
    pause: async (uuid) => {
        return await apiHelper.doApiCall("POST", `/campaigns/${uuid}/pause`);
    },
    resume: async (uuid) => {
        return await apiHelper.doApiCall("POST", `/campaigns/${uuid}/resume`);
    },
    approve: async (uuid) => {
        return await apiHelper.doApiCall("POST", `/campaigns/${uuid}/approve`);
    },
    decline: async (uuid, reason) => {
        return await apiHelper.doApiCall("POST", `/campaigns/${uuid}/decline`, {
            data: { reason },
        });
    },
    getUploadSignature: async (uuid) => {
        return await apiHelper.doApiCall(
            "GET",
            `/campaigns/${uuid}/uploadSignature`
        );
    },
    getImageUploadSignature: async (uuid, elementId, argKey, mvpId) => {
        let url = `/campaigns/${uuid}/imageUploadSignature?element=${elementId}`;

        if (mvpId) {
            url += `&mvp=${mvpId}`;
        }

        if (argKey) {
            url += `&arg=${argKey}`;
        }

        return await apiHelper.doApiCall("GET", url);
    },
};
