import { useState } from "react";
import { Textbox } from "./Textbox";
import { Icon } from "./Icon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CheckButton } from "./CheckButton";
import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import classNames from "classnames";

export const Buttons = ({
    options,
    value,
    onChange,
    label,
    error,
    max,
    isLoading,
    className,
    multi = false,
    size = "medium",
    sort,
}) => {
    const { t } = useTranslation();

    const [search, setSearch] = useState("");

    const handleChange = (option) => {
        if (multi) {
            if (value.includes(option.value)) {
                onChange(value.filter((v) => v !== option.value));
            } else {
                if (max !== undefined && value.length > max - 1) {
                    return;
                }

                onChange([...value, option.value]);
            }
        } else {
            onChange(option.value);
        }
    };

    let filteredOptions = (options || []).filter((option) =>
        option.label.toLowerCase().includes(search.toLowerCase())
    );

    if (sort) {
        filteredOptions.sort((a, b) => a.label.localeCompare(b.label));
    }

    const renderOptions = filteredOptions.map((option) => {
        let isActive = multi
            ? value.includes(option.value)
            : option.value === value;
        let isMaxedOut = multi && value?.length >= max;

        return (
            <Button
                key={`buttons-option-${option.value}`}
                variant={
                    option.value === value ? "secondaryActive" : "secondary"
                }
                onClick={() => handleChange(option)}
                size="large"
                subtitle={option.subtitle}
                wrapperClassName="contents"
            >
                {isMaxedOut ? (
                    <span
                        className={isActive ? "text-cash" : "text-neutral-500"}
                    >
                        {option.label}
                    </span>
                ) : (
                    option.label
                )}
            </Button>
        );
    });

    return (
        <div
            className={classNames(
                "grid gap-2",
                {
                    "grid-cols-1": options.length === 1,
                    "grid-cols-2": options.length === 2,
                    "grid-cols-3": options.length === 3,
                    "grid-cols-2 md:grid-cols-4": options.length >= 4,
                },
                className
            )}
        >
            {renderOptions}
        </div>
    );
};
