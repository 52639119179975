import Lottie from "react-lottie-player";

import hmhmhmhmAnimation from "./lotties/hmhmhmhm.json";
import neutralAnimation from "./lotties/neutral.json";
import stunnedAnimation from "./lotties/stunned.json";
import sleepyAnimation from "./lotties/sleepy.json";
import happyAnimation from "./lotties/happy.json";

const animationDataMap = {
    "hmhmhmhm": hmhmhmhmAnimation,
    "neutral": neutralAnimation,
    "stunned": stunnedAnimation,
    "sleepy": sleepyAnimation,
    "happy": happyAnimation,
};

export const Emoji = ({ emoji, play, ...props }) => {
    let animationData = animationDataMap[emoji];

    if (!animationData) {
        console.error(`No animation data found for emoji ${emoji}`);

        return null;
    }

    return <Lottie animationData={animationData} play={play} {...props} />;
};
