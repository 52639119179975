import { Page } from "../Page";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { RaffleCard } from "../cards/RaffleCard";
import { useNavigate } from "react-router";
import { WatchAdDialog } from "../dialogs/WatchAdDialog";
import { Loader } from "../Loader";
import { Message } from "../Message";
import { useTranslation } from "react-i18next";
import { randomIntFromInterval } from "../RaffleSpinner";
import { Advertisers } from "../Advertisers";
import { ApplicationContext } from "../context/ApplicationContext";
import { StageDialog } from "../dialogs/StageDialog";

export const PlayPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);
    const [applicationContext, applicationContextDispatch] =
        useContext(ApplicationContext);

    const [welcomeTranslationKey, setWelcomeTranslationKey] = useState(
        "text.welcome_back_1"
    );
    const [currentAd, setCurrentAd] = useState(false);
    const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);
    const [isStageDialogOpen, setIsStageDialogOpen] = useState(false);

    useEffect(() => {
        if (!!userContext?.user?.uuid && randomIntFromInterval(1, 1000) === 1) {
            setWelcomeTranslationKey("text.welcome_back_2");
        }
    }, [userContext?.user]);

    const filteredRaffles = (applicationContext.raffles || []).filter(
        (raffle) =>
            !applicationContext.myRaffles.length ||
            !applicationContext.myRaffles.find(
                (myRaffle) => myRaffle.raffle.uuid === raffle.uuid
            )
    );

    const handleClaim = (data) => {
        if (
            !!data &&
            !applicationContext.myRaffles.find((v) => v?.uuid === data?.uuid)
        ) {
            applicationContextDispatch({
                type: "SET_RAFFLES",
                payload: (applicationContext.raffles || []).filter(
                    (v) => v?.uuid !== data?.raffle?.uuid
                ),
            });
            applicationContextDispatch({
                type: "SET_MY_RAFFLES",
                payload: [...applicationContext.myRaffles, data],
            });
        } else {
            applicationContextDispatch({
                type: "SET_MY_RAFFLES",
                payload: applicationContext.myRaffles.map((v) => {
                    if (v?.uuid === data?.uuid) {
                        return data;
                    }

                    return v;
                }),
            });
        }

        if (
            !!currentAd?.campaign?.stageJson?.length &&
            currentAd?.campaign?.stageDuration > 0
        ) {
            setIsStageDialogOpen(true);
            setIsVideoDialogOpen(false);

            return;
        }

        setCurrentAd(null);
        setIsVideoDialogOpen(false);
    };

    const handleStageTicketsClaim = (data) => {
        if (
            !!data &&
            !applicationContext.myRaffles.find((v) => v?.uuid === data?.uuid)
        ) {
            applicationContextDispatch({
                type: "SET_RAFFLES",
                payload: (applicationContext.raffles || []).filter(
                    (v) => v?.uuid !== data?.uuid
                ),
            });
            applicationContextDispatch({
                type: "SET_MY_RAFFLES",
                payload: [...applicationContext.myRaffles, data],
            });
        } else {
            applicationContextDispatch({
                type: "SET_MY_RAFFLES",
                payload: applicationContext.myRaffles.map((v) => {
                    if (v?.uuid === data?.uuid) {
                        return data;
                    }

                    return v;
                }),
            });
        }

        setCurrentAd(null);
        setIsStageDialogOpen(false);
    };

    const renderMyRaffles = (applicationContext.myRaffles || [])
        .sort(sortMyRaffles)
        .map((v) => {
            return (
                <RaffleCard
                    key={`raffle-user-${v.uuid}`}
                    raffleUser={v}
                    raffle={v.raffle}
                    onClick={() => navigate(`/play/raffle/${v.raffle?.uuid}`)}
                    onDataReceive={(v) => {
                        setCurrentAd(v);
                        setIsVideoDialogOpen(true);
                    }}
                    isWatchDisabled={
                        !applicationContext.currentAdvertisers.length
                    }
                />
            );
        });

    const renderRaffles = filteredRaffles.map((v) => {
        const raffleUser = !!v.user ? v : undefined;

        return (
            <RaffleCard
                key={`raffle-user-${v.uuid}`}
                raffleUser={raffleUser}
                raffle={v.raffle || v}
                onClick={() =>
                    navigate(`/play/raffle/${v.raffle?.uuid || v.uuid}`)
                }
                onDataReceive={(v) => {
                    setCurrentAd(v);
                    setIsVideoDialogOpen(true);
                }}
                isWatchDisabled={!applicationContext.currentAdvertisers.length}
            />
        );
    });

    return (
        <Page>
            {userContext?.isLoading || applicationContext.isLoading ? (
                <div>
                    <div className="hidden sm:block">
                        <Loader duration="5.4s" className="h-8 mb-4 w-1/2" />
                    </div>
                    <div className="block sm:hidden">
                        <Loader duration="5.6s" className="h-8 mb-2" />
                        <Loader duration="5.8s" className="h-8 mb-4 w-2/3" />
                    </div>
                </div>
            ) : (
                <h2
                    className="text-2xl lg:text-3xl font-black mb-4"
                    dangerouslySetInnerHTML={{
                        __html: t(welcomeTranslationKey, {
                            value: userContext?.user?.username,
                        }),
                    }}
                />
            )}
            <Advertisers
                advertisers={applicationContext.currentAdvertisers}
                isLoading={applicationContext.isLoading}
                className="mb-8"
            />
            {applicationContext.isLoading && (
                <div className="space-y-8 mb-8">
                    <div className="space-y-4">
                        <Loader duration="6s" className="!h-6 w-3/4" />
                        <Loader duration="6.2s" className="!h-20" />
                        <Loader duration="6.4s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="6.6s" className="!h-6 w-3/4" />
                        <Loader duration="6.8s" className="!h-20" />
                        <Loader duration="7s" className="!h-20" />
                    </div>
                    <div className="space-y-4">
                        <Loader duration="7.2s" className="!h-6 w-3/4" />
                        <Loader duration="7.4s" className="!h-20" />
                        <Loader duration="7.6s" className="!h-20" />
                    </div>
                </div>
            )}
            <div className="mb-8">
                {!applicationContext.isLoading &&
                    (filteredRaffles.length ||
                        applicationContext.myRaffles.length) && (
                        <div>
                            {/* <h3 className="text-lg mb-4 text-secondary">
                        {t("title.participating_raffles")}
                    </h3> */}
                            <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                                {renderMyRaffles}
                                {renderRaffles}
                            </div>
                        </div>
                    )}
                {!applicationContext.isLoading &&
                    !(
                        filteredRaffles.length ||
                        applicationContext.myRaffles.length
                    ) && <Message>{t("text.no_raffles_available")}</Message>}
            </div>
            {!applicationContext.isLoading &&
                applicationContext.wonRaffles.length > 0 && (
                    <div className="mb-4">
                        <h3 className="text-2xl lg:text-3xl font-black mb-4 text-gradient-primary">
                            {t("title.win_congratulations")}
                        </h3>
                        <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
                            {(applicationContext.wonRaffles || []).map(
                                (raffleUser) => {
                                    return (
                                        <RaffleCard
                                            key={`raffle-user-${raffleUser.uuid}`}
                                            raffleUser={raffleUser}
                                            raffle={raffleUser.raffle}
                                            onClick={() =>
                                                navigate(
                                                    `/play/raffle/${raffleUser.raffle.uuid}`
                                                )
                                            }
                                            isWon
                                        />
                                    );
                                }
                            )}
                        </div>
                    </div>
                )}
            <WatchAdDialog
                open={isVideoDialogOpen}
                ad={currentAd}
                onCancel={() => {
                    setIsVideoDialogOpen(false);
                    setCurrentAd(null);
                }}
                onClaim={handleClaim}
            />
            <StageDialog
                open={isStageDialogOpen}
                campaign={currentAd?.campaign}
                value={currentAd?.campaign?.stageJson}
                onCancel={() => {
                    setCurrentAd(null);
                    setIsStageDialogOpen(false);
                }}
                onClaim={handleStageTicketsClaim}
                ad={currentAd}
            />
        </Page>
    );
};

const sortMyRaffles = (a, b) => {
    // First, check if either of the raffles is yearly
    if (
        a.raffle.repetition === "monthly" &&
        b.raffle.repetition !== "monthly"
    ) {
        return 1;
    } else if (
        a.raffle.repetition !== "monthly" &&
        b.raffle.repetition === "monthly"
    ) {
        return -1;
    } else if (
        a.raffle.repetition === "monthly" &&
        b.raffle.repetition === "monthly"
    ) {
        return 0;
    }

    // At this point, both raffles are not yearly
    // Handle cases where lastWatchedAt is missing
    if (!a.lastWatchedAt && !b.lastWatchedAt) {
        return 0;
    } else if (!a.lastWatchedAt) {
        return -1;
    } else if (!b.lastWatchedAt) {
        return 1;
    }

    // Both lastWatchedAt values are present, so we can safely compare them
    return new Date(a.lastWatchedAt) - new Date(b.lastWatchedAt);
};
