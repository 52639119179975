export const DE_DE = {
    label: {
        background: "Hintergrund",
        button_align: "Ausrichtung des Buttons",
        text_align: "Textausrichtung",
        image_position: "Bildposition",
        left: "Links",
        right: "Rechts",
        center: "Zentriert",
        cash_out_options: "Auszahlungsoptionen",
        extra_small: "Extra klein",
        extra_large: "Extra groß",
        title_size: "Titelgröße",
        product_slider: "Produkt-Slider",
        products: "Produkte",
        discount: "Rabatt",
        links: "Links",
        link: "Link",
        items: "Elemente",
        button_text: "Button-Text",
        button_link: "Button-Link",
        large: "Groß",
        medium: "Mittel",
        small: "Klein",
        size: "Größe",
        headline: "Überschrift",
        title: "Titel",
        text: "Text",
        image: "Bild",
        hero: "Hero-Sektion",
        paragraph: "Absatz",
        advertiser: "Werbetreibender",
        duration: "Dauer",
        firstname: "Vorname",
        lastname: "Nachname",
        email: "E-Mail",
        phone: "Telefon",
        company_name: "Firmenname",
        de_de: "Deutsch",
        en_gb: "Englisch",
        logo: "Logo",
        advertisement: "Werbung",
        prize_pool: "Preispool",
        tickets: "Tickets",
        participants: "Teilnehmer",
        your_tickets: "Ihre Tickets",
        win_chance: "Gewinnchance",
        password: "Passwort",
        current_password: "Aktuelles Passwort",
        new_password: "Neues Passwort",
        repeat_new_password: "Neues Passwort wiederholen",
        video: "Video",
        website_url: "Website-URL",
        description: "Beschreibung",
        tags: "Tags",
        price: "Preis",
        price_overview: "Preisübersicht",
        limit: "Limit",
        waiting_for_payment: "Warten auf Zahlung",
        payment_confirmed: "Zahlung bestätigt",
        draft: "Entwurf",
        waiting_for_approval: "Warten auf Freigabe",
        paused: "Pausiert",
        live: "Live",
        limit_reached: "Limit erreicht",
        declined: "Abgelehnt",
        status: "Status",
        watches: "Aufrufe",
        website_watches: "Website-Aufrufe",
        payment_failed: "Zahlung fehlgeschlagen",
        bank_account: "Bankkonto",
        golden_tickets: "Goldene Tickets",
        registered_since: "Registriert seit",
    },
    description: {
        website_url: "Wird am Ende Ihres Videos angezeigt",
        description: "Wird am Ende Ihres Videos angezeigt",
        campaign_website:
            "Zuschauer werden nach dem Ansehen Ihrer Werbung gebeten, Ihre Website zu besuchen. Ihre Website wird in adroulette.net eingebettet, um sicherzustellen, dass die Zuschauer für die ausgewählte Zeit auf Ihrer Website bleiben",
        campaign_tags:
            "Verwenden Sie Tags, um die Interessen Ihrer Zielgruppe anzusprechen",
        bank_account:
            "Sie benötigen ein gültiges Bankkonto, um Ihr Preisgeld zu beanspruchen. Weitere Informationen finden Sie in unseren <a class='underline' href='/terms-of-service#payout'>Nutzungsbedingungen</a>",
    },
    placeholder: {
        write: "Schreiben Sie etwas Schönes...",
        paypal_email: "Geben Sie Ihre PayPal-E-Mail ein",
        loading: "Laden...",
        search: "Suchen...",
        bank_account: "Geben Sie ein gültiges Bankkonto ein",
    },
    question: {
        cancel_editing:
            "Möchten Sie das Bearbeiten wirklich abbrechen? Ihre ungespeicherten Änderungen gehen verloren",
        delete_stage_element:
            "Möchten Sie <b>{{element}}</b> wirklich löschen?",
        pause_campaign:
            "Ihre Kampagne wird so lange pausiert, wie Sie möchten. Sie können sie jederzeit wieder aktivieren, ohne den Genehmigungsprozess erneut durchlaufen zu müssen",
        resume_campaign:
            "Ihre Kampagne wird wieder live geschaltet, ohne den Genehmigungsprozess erneut durchlaufen zu müssen",
        delete_campaign:
            "Möchten Sie diese Kampagne wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.",
        delete_account: "Möchten Sie Ihr Konto wirklich löschen?",
        delete_account_advertiser:
            "Ihr Abonnement wird gekündigt und alle Ihre Anzeigen werden dauerhaft gelöscht.",
    },
    subtitle: {
        advertisements: "Erstellen und verwalten Sie Ihre Anzeigen",
    },
    badge: {
        best_price: "Bester Preis",
    },
    INTEREST_ELECTRONICS: "Elektronik",
    INTEREST_FASHION: "Mode",
    INTEREST_BOOKS: "Bücher",
    INTEREST_FOOD: "Essen",
    INTEREST_TRAVEL: "Reisen",
    INTEREST_MUSIC: "Musik",
    INTEREST_SPORTS: "Sport",
    INTEREST_HEALTH: "Gesundheit",
    INTEREST_HOME: "Zuhause",
    INTEREST_BEAUTY: "Schönheit",
    INTEREST_ART: "Kunst",
    INTEREST_TECHNOLOGY: "Technologie",
    INTEREST_OUTDOORS: "Outdoor",
    INTEREST_TOYS: "Spielzeug",
    INTEREST_PETS: "Haustiere",
    INTEREST_EDUCATION: "Bildung",
    INTEREST_FITNESS: "Fitness",
    INTEREST_CRAFTS: "Handwerk",
    INTEREST_MOVIES: "Filme",
    INTEREST_AUTOMOTIVE: "Automobil",
    INTEREST_GARDENING: "Gartenarbeit",
    INTEREST_JEWELRY: "Schmuck",
    INTEREST_COOKING: "Kochen",
    INTEREST_PHOTOGRAPHY: "Fotografie",
    INTEREST_COLLECTIBLES: "Sammlerstücke",
    INTEREST_TOOLS: "Werkzeuge",
    INTEREST_GIFTS: "Geschenke",
    INTEREST_FURNITURE: "Möbel",
    INTEREST_PARTY: "Party",
    INTEREST_OFFICE: "Büro",
    INTEREST_BABY: "Baby",
    INTEREST_VINTAGE: "Vintage",
    INTEREST_HOBBIES: "Hobbys",
    INTEREST_DIY: "Heimwerken",
    INTEREST_GADGETS: "Gadgets",
    INTEREST_WELLNESS: "Wellness",
    INTEREST_ACCESSORIES: "Accessoires",
    INTEREST_ORGANIC: "Bio",
    INTEREST_GAMING: "Gaming",
    INTEREST_DECORATION: "Dekoration",
    INTEREST_NUTRITION: "Ernährung",
    INTEREST_COSMETICS: "Kosmetik",
    INTEREST_FOOTWEAR: "Schuhe",
    INTEREST_APPS: "Apps",
    INTEREST_INVESTING: "Investieren",
    COUNTRY_DE: "Deutschland",
    COUNTRY_TR: "Türkei",
    SUCCESS_DELETE_CAMPAIGN: "Kampagne gelöscht",
    SUCCESS_UPDATE: "Gespeichert",
    SUCCESS_CLAIM_TICKET: "Ticket eingelöst",
    SUCCESS_LOGIN: "Willkommen zurück!",
    SUCCESS_RAFFLE_CLAIMED: "Preis eingelöst",
    SUCCESS_CREATE: "Erstellt",
    SUCCESS_ACCOUNT_DELETE: "Konto gelöscht",
};
