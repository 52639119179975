import classNames from "classnames";
import { StageElementTextEditWrapper } from "../StageElementTextEditWrapper";

export const Headline = ({
    title,
    size = "medium",
    mvpKey,
    mvpIndex,
    className,
}) => {
    return (
        <h2
            className={classNames(
                "font-bold",
                {
                    "text-sm": size === "extraSmall",
                    "text-base": size === "small",
                    "text-lg": size === "medium",
                    "text-lg sm:text-2xl": size === "large",
                    "text-lg sm:text-3xl": size === "extraLarge",
                },
                className
            )}
        >
            <StageElementTextEditWrapper
                argKey="title"
                mvpKey={mvpKey}
                mvpIndex={mvpIndex}
                textareaClassName={className}
            >
                {title}
            </StageElementTextEditWrapper>
        </h2>
    );
};
