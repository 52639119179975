import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { Textbox } from "../Textbox";
import { Textarea } from "../Textarea";
import { Button } from "../Button";
import { ImageInput } from "../ImageInput";
import { advertiserUtils } from "../../utils/advertiserUtils";
import { useTranslation } from "react-i18next";
import { Field, FieldContainer } from "../Card";

export const AdvertiserUpdateForm = () => {
    const { t } = useTranslation();

    const [userContext, dispatch] = useContext(UserContext);

    const [name, setName] = useState(userContext?.user?.advertiser?.name);
    const [description, setDescription] = useState(
        userContext?.user?.advertiser?.description
    );
    const [logo, setLogo] = useState({
        blob: userContext?.user?.advertiser?.logoUrl,
    });
    const [websiteUrl, setWebsiteUrl] = useState(
        userContext?.user?.advertiser?.websiteUrl
    );
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setName(userContext?.user?.advertiser?.name);
        setDescription(userContext?.user?.advertiser?.description);
        setWebsiteUrl(userContext?.user?.advertiser?.websiteUrl);
        setLogo({
            blob: userContext?.user?.advertiser?.logoUrl,
        });
    }, [userContext?.user]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const updateResponse = await advertiserUtils.update(
                userContext?.user?.advertiser?.uuid,
                { name, description, websiteUrl, logo: logo?.file }
            );

            if (updateResponse.status === 200) {
                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext?.user,
                        advertiser: updateResponse.data.data,
                    },
                });
            }
        } catch (err) {
            console.error(err);
            setIsLoading(false);
        }

        setIsLoading(false);
    };

    const validate = () => {
        if (!name) {
            return false;
        }

        if (
            name === userContext?.user?.advertiser?.name &&
            description === userContext?.user?.advertiser?.description &&
            websiteUrl === userContext?.user?.advertiser?.websiteUrl &&
            logo?.blob === userContext?.user?.advertiser?.logoUrl
        ) {
            return false;
        }

        return true;
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <FieldContainer className="mb-4">
                    <Field>
                        <ImageInput
                            value={logo}
                            onChange={(v) => setLogo(v)}
                            label={t("label.logo")}
                            onRemove={() => setLogo({ file: null, blob: null })}
                        >
                            <img src={logo?.blob || logo} alt="Logo" />
                        </ImageInput>
                    </Field>
                    <Field>
                        <Textbox
                            label={t("label.company_name")}
                            placeholder={t("label.company_name")}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            maxLength={32}
                            className="mb-4"
                        />
                        <Textbox
                            label={t("label.website_url")}
                            type="url"
                            placeholder={t("label.website_url")}
                            value={websiteUrl}
                            onChange={(e) => setWebsiteUrl(e.target.value)}
                            maxLength={32}
                        />
                    </Field>
                    <Field colSpan={2}>
                        <Textarea
                            label={t("label.description")}
                            placeholder={t("label.description")}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            maxLength={240}
                        />
                    </Field>
                </FieldContainer>
                <div className="sm:col-span-12 text-right">
                    <Button
                        type="submit"
                        variant="primary"
                        disabled={!validate()}
                        isLoading={isLoading}
                    >
                        {t("button.save")}
                    </Button>
                </div>
            </form>
        </div>
    );
};
