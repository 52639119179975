import { stageStudioElements } from "./elements";
import objectPath from "object-path";

export const USER_VIDEO_SHARE_MULTIPLIER = 0.5;
export const USER_STAGE_SHARE_MULTIPLIER = 0.3;
export const REACTION_TICKETS = 2;

export const validateStage = (stage) => {
    const response = {
        ok: false,
        images: [],
        stage,
    };

    if (!stage) {
        return response;
    }

    for (let i = 0; i < stage.length; i++) {
        let element = stage[i];
        let config = stageStudioElements[element.type];

        if (!config) {
            console.error(
                `No config found for element of type ${element.type}`
            );

            stage.splice(i, 1);

            continue;
        }

        let elementKeys = Object.keys(element || {});

        for (let j = 0; j < elementKeys.length; j++) {
            let key = elementKeys[j];
            let value = element[key];

            switch (key) {
                case "id":
                case "sort":
                    if (isNaN(Number(value))) {
                        console.error(
                            `Invalid number value for ${key} with value ${value}`
                        );

                        return response;
                    }

                    break;
                case "type":
                    break;
                case "props":
                    let propKeys = Object.keys(value || {});

                    for (let k = 0; k < propKeys.length; k++) {
                        let propKey = propKeys[k];
                        let propValue = value[propKey];

                        let propConfig = config.arguments[propKey];

                        const { ok, images } = validateProp(
                            propKey,
                            propValue,
                            propConfig
                        );

                        if (!ok) {
                            return response;
                        }

                        if (images.length) {
                            images.forEach((v) => {
                                objectPath.set(
                                    response.stage[i],
                                    `props.${v.path}`,
                                    v.value.replace("-preview", "")
                                );
                            });

                            response.images = [...response.images, ...images];
                        }
                    }

                    break;
                default:
                    console.error(
                        `Invalid element key ${key} with value ${value}`
                    );

                    return response;
            }
        }
    }

    response.ok = true;

    return response;
};

const validateProp = (key, value, config) => {
    const response = {
        ok: false,
        images: [],
    };

    if (!config) {
        console.error(`No config given for prop ${key}`);

        return response;
    }

    switch (config.type) {
        case "text":
        case "markdown":
            if (typeof value !== "string") {
                console.error(`Invalid type ${typeof value} for ${key}`);

                return response;
            }

            if (!!config.maxLength && value.length > config.maxLength) {
                console.error(`Length exceeded for ${key} with value ${value}`);

                return response;
            }

            break;
        case "select":
            if (!config.options?.[value]) {
                console.error(`Invalid option ${value} for ${key}`);

                return response;
            }

            break;
        case "buttons":
            if (!config.options?.[value]) {
                console.error(`Invalid option ${value} for ${key}`);

                return response;
            }

            break;
        case "image":
            if (typeof value !== "string") {
                console.error(`Invalid type ${typeof value} for ${key}`);

                return response;
            }

            if (
                value !== "/placeholder-image.png" &&
                !value.startsWith("https://res.cloudinary.com")
            ) {
                console.error(`Invalid image source ${value} for ${key}`);

                return response;
            }

            if (
                value &&
                (value.endsWith("-preview.jpg") ||
                    value.endsWith("-preview.jpeg") ||
                    value.endsWith("-preview.png"))
            ) {
                response.images.push({
                    path: key,
                    value,
                });
            }

            break;
        case "mvp":
            if (!Array.isArray(value)) {
                console.error(
                    `Value is not an array for MVP ${key} with value ${JSON.stringify(
                        value
                    )}`
                );

                return response;
            }

            if (!!config.maxEntries && value.length > config.maxEntries) {
                console.error(
                    `Max entries exceeded for MVP ${key} with value ${JSON.stringify(
                        value
                    )}`
                );

                return response;
            }

            for (let i = 0; i < value.length; i++) {
                let element = value[i];

                let keys = Object.keys(element || {});

                for (let j = 0; j < keys.length; j++) {
                    let mvpKey = keys[j];
                    let mvpValue = element[mvpKey];
                    let mvpConfig = config.arguments[mvpKey];

                    if (mvpKey === "id") {
                        if (typeof mvpValue !== "number" || isNaN(mvpValue)) {
                            console.error(`Invalid MVP ID ${mvpValue}`);

                            return response;
                        }

                        continue;
                    }

                    if (!mvpConfig) {
                        console.error(`MVP config not found for ${mvpKey}`);

                        return response;
                    }

                    let { ok, images } = validateProp(
                        mvpKey,
                        mvpValue,
                        mvpConfig
                    );

                    images = images.map((v) => ({
                        ...v,
                        path: `${key}.${i}.${mvpKey}`,
                    }));

                    if (!ok) {
                        console.error(
                            `Failed to validate ${mvpKey} with value ${mvpValue}`
                        );

                        return response;
                    }

                    if (images.length) {
                        response.images = [...response.images, ...images];
                    }
                }
            }

            break;
        default:
            console.error(`Invalid prop type ${config.type}`);

            return response;
    }

    response.ok = true;

    return response;
};

export const parseStage = (stageJson) => {
    try {
        let parsedStage = JSON.parse(stageJson);

        return parsedStage;
    } catch (err) {
        console.error("Failed to parse stage json", err);

        return null;
    }
};
