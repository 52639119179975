import classNames from "classnames";

export const Price = ({ price, discount, className, ...props }) => {
    if (!price && !discount) {
        return null;
    }

    return (
        <p className={classNames("text-right space-x-1", className)} {...props}>
            <span
                className={classNames("whitespace-nowrap", {
                    "line-through text-tertiary": discount,
                })}
            >
                {price}
            </span>
            {!!discount && (
                <span className="text-cash whitespace-nowrap">{discount}</span>
            )}
        </p>
    );
};
