import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import {
    StageElementContext,
    useElementConfig,
    useElementValue,
    useStageElementEditState,
} from "../context/StageElementContext";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Circle } from "../Circle";
import { Icon } from "../Icon";
import { StageEditorContext } from "./StageEditorContext";
import { faCheck, faClose, faICursor } from "@fortawesome/free-solid-svg-icons";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { IconButton } from "../IconButton";

export const StageElementTextEditWrapper = ({
    children,
    argKey,
    className,
    textareaClassName,
    mvpIndex,
    mvpKey,
    ...props
}) => {
    const [stageElementContext] = useContext(StageElementContext);
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementConfig = useElementConfig();
    const elementValue = useElementValue();
    const isEditing = useStageElementEditState();
    const [localTextValue, setLocalTextValue] = useState(
        elementValue?.props?.[mvpKey]?.[mvpIndex]?.[argKey] ||
            elementValue?.props?.[argKey]
    );
    const { t } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);

    const markdownWrapperRef = useRef(null);
    const editorRef = useRef(null);

    const handleBlur = () => {
        let textarea = editorRef.current?.textarea;

        if (!textarea) {
            return;
        }

        let updatedValue = JSON.parse(JSON.stringify(elementValue));

        if (mvpKey) {
            updatedValue.props[mvpKey][mvpIndex][argKey] = localTextValue;
        } else {
            updatedValue.props[argKey] = localTextValue;
        }

        dispatch({
            type: "UPDATE_ELEMENT",
            payload: updatedValue,
        });
    };

    const handleTextareaBlur = () => {
        let updatedValue = JSON.parse(JSON.stringify(elementValue));

        if (mvpKey) {
            updatedValue.props[mvpKey][mvpIndex][argKey] = localTextValue;
        } else {
            updatedValue.props[argKey] = localTextValue;
        }

        setIsFocused(false);

        dispatch({
            type: "UPDATE_ELEMENT",
            payload: updatedValue,
        });
    };

    useEffect(() => {
        if (!markdownWrapperRef.current) {
            return;
        }

        let listener = (e) => {
            if (markdownWrapperRef.current.contains(e.target)) {
                setIsFocused(true);
            } else {
                handleBlur();
                setIsFocused(false);
            }
        };

        window.addEventListener("click", listener);

        return () => window.removeEventListener("click", listener);
    }, [isFocused, markdownWrapperRef, handleBlur]);

    if (!elementConfig) {
        console.error(`Element config not found`);

        return null;
    }

    const argConfig =
        elementConfig?.arguments?.[mvpKey]?.arguments?.[argKey] ||
        elementConfig.arguments[argKey];

    if (!argConfig) {
        console.error(`Argument config for argument ${argKey} not found`);

        return null;
    }

    const handleMarkdownChange = (value) => {
        setLocalTextValue(value);
    };

    const handleTextInputChange = (e) => {
        let value = e?.target?.value;

        setLocalTextValue(value);
    };

    const handleCancel = () => {
        setIsFocused(false);

        setLocalTextValue(
            elementValue?.props?.[mvpKey]?.[mvpIndex]?.[argKey] ||
                elementValue?.props?.[argKey]
        );
    };

    return (
        <div
            {...props}
            className={classNames(
                "relative w-full",
                {
                    "hover:bg-white/5 hover:rounded-md transition-all p-1":
                        stageElementContext.isEditing,
                    "rounded-md !bg-white/10": isFocused && isEditing,
                    "!cursor-pointer": stageElementContext.isEditor,
                },
                className
            )}
        >
            {stageElementContext.isEditing && !isFocused && (
                <div className="absolute -top-2 -right-1">
                    <Circle className="bg-black w-4 h-4 outline-black outline">
                        <Icon icon={faICursor} className="!text-cash h-[8px]" />
                    </Circle>
                </div>
            )}
            {isEditing ? (
                argConfig.type === "markdown" ? (
                    <div ref={markdownWrapperRef}>
                        {isFocused ? (
                            <MarkdownEditor
                                textareaProps={{
                                    maxLength: argConfig.maxLength,
                                    placeholder: t("placeholder.write"),
                                    isFocused: isFocused,
                                    autoFocus: true,
                                }}
                                value={localTextValue}
                                onChange={handleMarkdownChange}
                                onClose={handleCancel}
                                ref={editorRef}
                            />
                        ) : (
                            <div className="min-h-6">
                                <Markdown
                                    source={localTextValue}
                                    className={className}
                                    placeholder={t("placeholder.write")}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <input
                        type="text"
                        className={classNames(
                            "block w-full outline-0 text-inherit bg-transparent !p-0 !m-0 min-h-4 overflow-hidden resize-none",
                            textareaClassName
                        )}
                        onChange={handleTextInputChange}
                        value={localTextValue}
                        rows={1}
                        maxLength={argConfig.maxLength}
                        onFocus={() => setIsFocused(true)}
                        onBlur={handleTextareaBlur}
                        placeholder={t("placeholder.write")}
                    />
                )
            ) : argConfig.type === "markdown" ? (
                <Markdown source={localTextValue} className={className} />
            ) : (
                children
            )}
        </div>
    );
};

const Markdown = ({ source, className, placeholder }) => {
    if (!source) {
        return <p className="text-tertiary">{placeholder}</p>;
    }

    return (
        <MDEditor.Markdown
            source={source}
            style={{ background: "transparent" }}
            className={classNames("!text-secondary", className)}
            allowedElements={[
                "strong",
                "p",
                "ul",
                "ol",
                "li",
                "hr",
                "blockquote",
                "del",
                "em",
                // "table",
                // "tr",
                // "td",
                // "th",
                // "tbody",
                // "thead",
            ]}
        />
    );
};

export const MarkdownEditor = forwardRef(
    ({ onAccept, onClose, ...props }, ref) => {
        return (
            <div className="relative">
                <MDEditor
                    ref={ref}
                    preview="edit"
                    commands={[
                        commands.bold,
                        commands.italic,
                        commands.unorderedListCommand,
                        commands.orderedListCommand,
                        commands.divider,
                        commands.hr,
                        commands.quote,
                        commands.strikethrough,
                    ]}
                    extraCommands={
                        !!onClose
                            ? [
                                  commands.group([], {
                                      name: "close",
                                      groupName: "close",
                                      icon: (
                                          <Icon
                                              icon={faClose}
                                              className="!text-secondary"
                                          />
                                      ),
                                      execute: onClose,
                                      buttonProps: {
                                          "aria-label": "Close",
                                      },
                                  }),
                              ]
                            : []
                    }
                    style={{
                        background: "transparent",
                        border: "none",
                    }}
                    height="auto"
                    {...props}
                />
                {!!onAccept && (
                    <div className="absolute right-2 bottom-2">
                        <IconButton
                            onClick={onAccept}
                            icon={faCheck}
                            size="medium"
                            variant="secondaryActive"
                            iconProps={{ className: "!text-cash" }}
                        />
                    </div>
                )}
            </div>
        );
    }
);
