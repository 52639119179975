import classNames from "classnames";
import { forwardRef, useRef } from "react";
import { Button } from "./Button";
import { faPhotoFilm } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export const VideoInput = forwardRef(
    (
        {
            value,
            onChange,
            className,
            label,
            allowRemove,
            error,
            onLoadedData,
            disabled,
            isLoading,
        },
        ref
    ) => {
        const { t } = useTranslation();

        const inputRef = useRef();

        const handleClick = () => {
            if (inputRef.current) {
                inputRef.current.click();
            }
        };

        const handleChange = (e) => {
            const file = e.target.files[0];

            if (!file || disabled) {
                return;
            }

            const fr = new FileReader();

            fr.readAsDataURL(file);

            fr.onload = ({ target }) => {
                onChange({ blob: target.result, file });
            };
        };

        return (
            <div className={className}>
                {!!label && (
                    <label className="block mb-1 text-primary text-opacity-90 font-semibold">
                        {label}
                    </label>
                )}
                {!!value?.blob && (
                    <div className="mb-2">
                        <div className="relative rounded-lg overflow-hidden mb-3">
                            <video
                                src={value?.blob}
                                controls
                                className="w-full"
                                ref={ref}
                                poster={
                                    value?.blob?.endsWith(".mp4") &&
                                    value?.blob?.replace(".mp4", ".jpg")
                                }
                                onLoadedData={onLoadedData}
                            />
                        </div>
                        {!!allowRemove && (
                            <Button
                                variant="secondary"
                                className="w-full"
                                size="small"
                                onClick={() =>
                                    onChange({ blob: null, file: null })
                                }
                            >
                                {t("button.remove")}
                            </Button>
                        )}
                    </div>
                )}
                {!!error && <p className="text-red mb-2 text-sm">{error}</p>}
                {!value?.blob ? (
                    <Button
                        variant="secondary"
                        className="w-full"
                        wrapperClassName="w-full"
                        onClick={handleClick}
                        icon={faPhotoFilm}
                        size="large"
                        isLoading={isLoading}
                    >
                        <p className="my-16">{t("button.choose_video")}</p>
                    </Button>
                ) : (
                    <Button
                        variant="secondary"
                        className="w-full"
                        wrapperClassName="w-full"
                        onClick={handleClick}
                        isLoading={isLoading}
                    >
                        {t("button.choose_other_video")}
                    </Button>
                )}
                <input
                    type="file"
                    accept="video/mp4"
                    ref={inputRef}
                    onChange={handleChange}
                    hidden
                    disabled={disabled}
                />
            </div>
        );
    }
);
