import {
    faChampagneGlasses,
    faCrown,
    faPlay,
    faTicket,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "../Card";
import { Button } from "../Button";
import { useContext, useEffect, useState } from "react";
import { Icon } from "../Icon";
import { NumberFormatter, PriceFormatter } from "../../helpers/number.helper";
import { dateDiff } from "../../helpers/date.helper";
import classNames from "classnames";
import { UserContext } from "../context/UserContext";
import { raffleUtils } from "../../utils/raffleUtils";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { CountUp } from "../CountUp";

export const RaffleCard = ({
    raffle,
    raffleUser,
    onClick,
    onDataReceive,
    isWon,
    isWatchDisabled,
}) => {
    const { t } = useTranslation();
    const [userContext] = useContext(UserContext);

    const [endText, setEndText] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isWatchAdEnabled, setIsWatchAdEnabled] = useState(false);
    const [nextWatchProgress, setNextWatchProgress] = useState(false);
    const [progress, setProgress] = useState(0);

    const renderEndText = () => {
        setEndText(getEndTextElement(raffle, null, t));
    };

    useEffect(() => {
        let endTextInterval;

        renderEndText();

        if (raffle?.endAt) {
            endTextInterval = setInterval(renderEndText, 1000);
        }

        return () => clearInterval(endTextInterval);
    }, [raffle?.endAt]);

    const updateProgress = () => {
        const currentDate = new Date();
        const lastWatchedTime = new Date(raffleUser?.lastWatchedAt);
        const elapsedTime = currentDate.getTime() - lastWatchedTime.getTime();
        const timeLimit = 900000; // 5 minutes in milliseconds

        if (raffleUser?.lastWatchedAt && elapsedTime < timeLimit) {
            setIsWatchAdEnabled(false);
            const progress = (elapsedTime / timeLimit) * 100;
            setNextWatchProgress(progress);
        } else {
            setIsWatchAdEnabled(true);
        }

        const endDate = new Date(raffle?.endAt);

        if (endDate) {
            const remainingTime = endDate.getTime() - currentDate.getTime();
            const totalTime =
                endDate.getTime() - new Date(raffle.startAt).getTime();

            // Ensure remainingTime is non-negative to avoid negative progress values
            const progress = 100 - (remainingTime / totalTime) * 100;

            setProgress(progress);
        }
    };

    useEffect(() => {
        updateProgress();

        let progressInterval;

        progressInterval = setInterval(() => {
            updateProgress();
        }, 1000);

        return () => clearInterval(progressInterval);
    }, [raffleUser?.lastWatchedAt]);

    const handleWatch = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsLoading(true);

        try {
            const watchResponse = await raffleUtils.watch(raffle.uuid);

            if (watchResponse.status === 200) {
                if (onDataReceive) {
                    onDataReceive({
                        ...watchResponse.data.data,
                        raffle,
                    });
                }
            }
        } catch (err) {
            console.error(err);

            setIsLoading(false);
        }

        setIsLoading(false);
    };

    if (!raffle) {
        return;
    }

    let variant = "neutral";

    if (isWon && raffle.repetition !== "yearly") {
        variant = "positive";
    } else if (raffle.repetition === "yearly") {
        variant = "yellow";
    }

    const currentDate = new Date();

    const raffleDate = new Date(raffle.createdAt);

    return (
        <Card onClick={onClick} progress={!isWon && progress} variant={variant}>
            <div
                className={classNames("grid items-center", {
                    "xs:grid-cols-[auto_1fr_auto]": !isWon,
                    "grid-cols-[auto_1fr_auto]": isWon,
                })}
            >
                {raffle.repetition === "yearly" ? (
                    <div className="xs:visible xs:w-auto xs:h-auto mr-0 ml-0 w-0 h-0 items-center justify-center invisible xs:mr-4 sm:ml-1 text-center">
                        <Icon
                            icon={faChampagneGlasses}
                            className="!text-yellow text-2xl"
                        />
                        <p className="text-yellow text-xs font-black">
                            {raffleDate.getFullYear() + 1}
                        </p>
                    </div>
                ) : (
                    // Placeholder for grid
                    <div className="w-0" />
                )}
                <div className={classNames("mr-4")}>
                    <CountUp
                        value={raffle.ticketCount * raffle.ticketValue}
                        className={classNames("mr-2 font-black text-xl", {
                            "text-cash-secondary":
                                raffle.repetition !== "yearly",
                            "text-yellow": raffle.repetition === "yearly",
                        })}
                        animate={!isWon}
                    />
                    {/* {raffleUser?.tickets && (
                        <div className="mr-2 inline-block">
                            <Icon
                                icon={faCrown}
                                className="!text-primary/60 mr-1"
                                size="xs"
                            />
                            <span className="text-sm text-primary/60">
                                {(
                                    (raffleUser?.tickets / raffle.ticketCount) *
                                    100
                                ).toFixed(2)}
                                %
                            </span>
                        </div>
                    )} */}
                    <div
                        className={classNames("text-base space-x-2", {
                            "text-primary/30": raffle.reptition !== "yearly",
                            "!text-yellow/60": raffle.repetition === "yearly",
                        })}
                    >
                        <span className="text-inherit">
                            <span className="text-inherit font-semibold">
                                {raffle.name}
                            </span>
                            {!!raffle.repetitionCount && (
                                <span className="ml-1 font-normal text-inherit">
                                    #{raffle.repetitionCount}
                                </span>
                            )}
                        </span>
                        {!isWon && (
                            <div className="inline-flex space-x-2">
                                {/* <div className="inline-flex items-center">
                                    <Icon
                                        icon={faUser}
                                        className="!text-primary/30 mr-1"
                                        size="xs"
                                    />
                                    <span className="text-xs text-tertiary">
                                        {raffle.userCount}
                                    </span>
                                </div> */}
                                <div className="inline-flex items-center">
                                    <span className="text-xs text-tertiary mr-1">
                                        {NumberFormatter.format(
                                            raffle.ticketCount
                                        )}
                                    </span>
                                    <Icon
                                        icon={faTicket}
                                        className="!text-primary/30"
                                        size="xs"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isWon && (
                    <div className="text-center">
                        <Icon
                            icon={faCrown}
                            className={classNames("text-xl", {
                                "!text-cash-secondary":
                                    raffle.repetition !== "yearly",
                                "!text-yellow": raffle.repetition === "yearly",
                            })}
                        />
                        <p
                            className={classNames("text-xs font-black", {
                                "!text-cash-secondary":
                                    raffle.repetition !== "yearly",
                                "!text-yellow": raffle.repetition === "yearly",
                            })}
                        >
                            You won!
                        </p>
                    </div>
                )}
                {!isWon && !raffle.uuidWinner && (
                    <Button
                        variant="primary"
                        icon={faPlay}
                        className="shrink-0 self-stretch min-h-12 mt-2 xs:mt-0"
                        wrapperClassName="justify-self-end"
                        disabled={
                            raffle?.idWinner ||
                            !isWatchAdEnabled ||
                            isWatchDisabled
                        }
                        onClick={handleWatch}
                        isLoading={isLoading}
                        progress={
                            nextWatchProgress > 0 &&
                            !isWatchAdEnabled &&
                            Math.ceil(nextWatchProgress)
                        }
                    >
                        {!!raffleUser ? t("button.watch") : t("button.join")}
                    </Button>
                )}
            </div>
        </Card>
    );
};

export const getEndTextElement = (raffle, className, t) => {
    const currentDate = new Date();
    const endDate = new Date(raffle?.endAt);

    if (!t || currentDate > endDate) {
        return;
    }

    const { days, hours, minutes, seconds } = dateDiff(currentDate, endDate);

    if (days > 0) {
        return (
            <p
                className={classNames("text-sm", className)}
                dangerouslySetInnerHTML={{
                    __html: t("text.days_hours_minutes_seconds_left", {
                        days,
                        hours,
                        minutes,
                        seconds,
                    }),
                }}
            />
        );
    }

    if (hours > 0) {
        return (
            <p
                className={classNames("text-sm", className)}
                dangerouslySetInnerHTML={{
                    __html: t("text.hours_minutes_seconds_left", {
                        days,
                        hours,
                        minutes,
                        seconds,
                    }),
                }}
            />
        );
    }

    if (minutes > 0) {
        return (
            <p
                className={classNames("text-sm", className)}
                dangerouslySetInnerHTML={{
                    __html: t("text.minutes_seconds_left", {
                        days,
                        hours,
                        minutes,
                        seconds,
                    }),
                }}
            />
        );
    }

    if (seconds > 0) {
        return (
            <p
                className={classNames("text-sm", className)}
                dangerouslySetInnerHTML={{
                    __html: t("text.seconds_left", {
                        days,
                        hours,
                        minutes,
                        seconds,
                    }),
                }}
            />
        );
    }

    return;
};
