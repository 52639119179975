import {
    PaymentElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { Button } from "../Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const AdPaymentMethodForm = ({ returnUrl }) => {
    const { t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null;
        }

        setIsLoading(true);

        const { error, setupIntent } = await stripe.confirmSetup({
            // `Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: returnUrl,
            },
            redirect: "if_required",
        });

        if (error) {
            // Show error to your customer (e.g., payment details incomplete)
            setError(error.message);
        } else if (setupIntent.status === "requires_action") {
            // Handle next_action if needed (e.g., 3D Secure authentication)
            stripe
                .handleNextAction(setupIntent.client_secret)
                .then((result) => {
                    if (result.error) {
                        // Show error in payment form
                        setError(result.error.message);
                    } else {
                        // The setup has been confirmed
                        window.location.href = returnUrl;
                    }
                });
        } else {
            // The setup has been confirmed
            window.location.href = returnUrl;
        }

        setIsLoading(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <PaymentElement className="mb-4" />
            {!!error && <p className="text-red mb-2 font-semibold">{error}</p>}
            <Button
                variant="primary"
                disabled={!stripe}
                className="w-full"
                size="large"
                type="submit"
                isLoading={isLoading}
            >
                {t("button.subscribe")}
            </Button>
        </form>
    );
};
