import {
    cloneElement,
    createContext,
    useContext,
    useEffect,
    useReducer,
} from "react";
import { stageEditorContextReducer } from "./StageEditorContext.reducer";
import { FooterMenu } from "../FooterMenu";
import { useTranslation } from "react-i18next";
import { StageEditorControls } from "./StageEditorControls";
import { Button } from "../Button";
import { StageElement } from "./StageElement";
import { Card } from "../Card";

const initialState = {
    currentElement: null,
    isSettingsOpen: false,
    campaign: null,
};

export const StageEditorContext = createContext();

export const StageEditorContextProvider = ({
    value,
    campaign,
    onChange,
    children,
}) => {
    const { t } = useTranslation();

    const [store, dispatch] = useReducer(stageEditorContextReducer, {
        ...initialState,
        campaign,
        value,
    });

    useEffect(() => {
        if (onChange) {
            onChange(store.value);
        }
    }, [store.value, onChange]);

    const handleChange = (key, value) => {
        if (!store.currentElement) {
            return;
        }

        let updatedValue = JSON.parse(
            JSON.stringify(store.currentElement.value)
        );

        if (store.currentElement.mvpKey) {
            updatedValue.props[store.currentElement.mvpKey][
                store.currentElement.mvpIndex
            ][key] = value;
        } else {
            updatedValue.props[key] = value;
        }

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: { ...store.currentElement, value: updatedValue },
        });
    };

    const handleSave = () => {
        dispatch({
            type: "UPDATE_ELEMENT",
            payload: store.currentElement.value,
        });

        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: null,
        });
    };

    const handleCancel = () => {
        dispatch({
            type: "SET_CURRENT_ELEMENT",
            payload: null,
        });
    };

    return (
        <StageEditorContext.Provider value={[store, dispatch]}>
            {children}
            <FooterMenu
                open={!!store.currentElement}
                onClose={() =>
                    dispatch({ type: "SET_CURRENT_ELEMENT", payload: null })
                }
                title={t("title.edit_x", {
                    x: t(store.currentElement?.title),
                })}
                buttons={
                    <>
                        <Button variant="tertiary" onClick={handleCancel}>
                            {t("button.cancel")}
                        </Button>
                        <Button onClick={handleSave}>{t("button.save")}</Button>
                    </>
                }
            >
                <Card className="mb-4">
                    <div className="sm:px-8 flex justify-center">
                        {!!store.currentElement?.children ? (
                            <div className="max-w-64">
                                {!!store.currentElement?.children &&
                                    cloneElement(
                                        store.currentElement?.children,
                                        {
                                            ...(store.currentElement?.value
                                                ?.props?.[
                                                store.currentElement?.mvpKey
                                            ]?.[
                                                store.currentElement?.mvpIndex
                                            ] ||
                                                store.currentElement?.value
                                                    ?.props ||
                                                {}),
                                        }
                                    )}
                            </div>
                        ) : (
                            <StageElement
                                element={store.currentElement?.value}
                                value={store.currentElement?.value}
                            />
                        )}
                    </div>
                </Card>
                <StageEditorControls
                    elementConfig={store.currentElement}
                    value={
                        store.currentElement?.mvpKey
                            ? store.currentElement?.value?.props[
                                  store.currentElement?.mvpKey
                              ][store.currentElement?.mvpIndex]
                            : store.currentElement?.value
                    }
                    onChange={handleChange}
                    disableGroups
                />
            </FooterMenu>
        </StageEditorContext.Provider>
    );
};

export const useElementCampaign = () => {
    const [stageElementContext] = useContext(StageEditorContext);

    return stageElementContext?.campaign;
};
