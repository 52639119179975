import { useContext, useState } from "react";
import {
    StageElementContext,
    useElementConfig,
    useElementValue,
} from "../context/StageElementContext";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Circle } from "../Circle";
import { Icon } from "../Icon";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { StageEditorContext, useElementCampaign } from "./StageEditorContext";
import { StageEditorImageUpload } from "./StageEditorImageUpload";

export const StageElementImageEditWrapper = ({
    children,
    argKey,
    title,
    mvpIndex,
    mvpKey,
    className,
    ...props
}) => {
    const [stageElementContext] = useContext(StageElementContext);
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const elementConfig = useElementConfig();
    const elementValue = useElementValue();
    const campaign = useElementCampaign();
    const { t } = useTranslation();

    const [value, setValue] = useState({
        secure_url:
            elementValue?.props?.[mvpKey]?.[mvpIndex]?.[argKey] ||
            elementValue?.props?.[argKey],
    });

    if (!elementConfig) {
        console.error(`Element config not found`);

        return null;
    }

    const argConfig =
        elementConfig?.arguments?.[mvpKey]?.arguments?.[argKey] ||
        elementConfig.arguments[argKey];

    if (!argConfig) {
        console.error(`Argument config for argument ${argKey} not found`);

        return null;
    }

    const handleUpload = (cloudinary) => {
        let updatedValue = JSON.parse(JSON.stringify(elementValue));

        if (mvpKey) {
            updatedValue.props[mvpKey][mvpIndex][argKey] =
                cloudinary?.secure_url || "/placeholder-image.png";
        } else {
            updatedValue.props[argKey] =
                cloudinary?.secure_url || "/placeholder-image.png";
        }

        dispatch({
            type: "UPDATE_ELEMENT",
            payload: updatedValue,
        });
    };

    const mvpId = elementValue?.props?.[mvpKey]?.[mvpIndex]?.id;

    return (
        <>
            <div
                {...props}
                className={classNames(
                    "outline-0 block",
                    {
                        "!cursor-pointer": stageElementContext.isEditor,
                    },
                    className
                )}
            >
                {stageElementContext.isEditing ? (
                    <div className="relative">
                        <StageEditorImageUpload
                            argKey={argKey}
                            value={value}
                            mvpId={mvpId}
                            onUpload={handleUpload}
                            campaign={campaign}
                        >
                            {children}
                        </StageEditorImageUpload>
                        <div className="absolute -top-2 -right-1 z-10">
                            <Circle className="bg-black outline outline-black w-4 h-4 transition-all">
                                <Icon
                                    icon={faImage}
                                    size="2xs"
                                    className="!text-cash"
                                />
                            </Circle>
                        </div>
                    </div>
                ) : (
                    children
                )}
            </div>
        </>
    );
};
