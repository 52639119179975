import classNames from "classnames";
import { Message } from "./Message";
import { Emoji } from "./Emoji";
import { useTranslation } from "react-i18next";
import { ReactionsChart } from "./ReactionsChart";

export const CampaignReactions = ({ campaign, className, ...props }) => {
    const { t } = useTranslation();

    const mostReactions = Object.entries({
        veryNegativeReactions: campaign.veryNegativeReactionsm,
        negativeReactions: campaign.negativeReactions,
        neutralReactions: campaign.neutralReactions,
        positiveReactions: campaign.positiveReactions,
        veryPositiveReactions: campaign.veryPositiveReactions,
    }).reduce(
        (prev, [key, value]) => {
            if (value > prev.value) {
                return {
                    key,
                    value,
                };
            }

            return prev;
        },
        {
            key: "neutralReactions",
            value: 0,
        }
    );

    let emoji, text;

    switch (mostReactions.key) {
        case "veryNegativeReactions":
            emoji = "hmhmhmhm";
            text = "text.very_negative_reactions";

            break;
        case "negativeReactions":
            emoji = "sleepy";
            text = "text.negative_reactions";

            break;
        case "positiveReactions":
            emoji = "happy";
            text = "text.positive_reactions";

            break;
        case "veryPositiveReactions":
            emoji = "stunned";
            text = "text.very_positive_reactions";

            break;
        case "neutralReactions":
        default:
            emoji = "neutral";
            text = "text.neutral_reactions";
    }

    return (
        <Message className={classNames("px-4 py-3", className)} {...props}>
            <div className="grid md:grid-cols-[auto_1fr] items-center justify-items-center gap-4 mb-4">
                <Emoji emoji={emoji} className="max-w-20" play />
                <p
                    className="text-secondary text-center md:text-left"
                    dangerouslySetInnerHTML={{ __html: t(text) }}
                />
            </div>
            <ReactionsChart campaign={campaign} />
        </Message>
    );
};
