import { useContext, useEffect, useState } from "react";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import classNames from "classnames";
import { Icon } from "../Icon";
import { faSpinner, faTicket } from "@fortawesome/free-solid-svg-icons";
import { AdVideo } from "../AdVideo";
import { LogoLoader } from "../LogoLoader";
import { UserContext } from "../context/UserContext";
import { raffleUtils } from "../../utils/raffleUtils";
import { useTranslation } from "react-i18next";
import { ApplicationContext } from "../context/ApplicationContext";
import Turnstile, { useTurnstile } from "react-turnstile";
import { evaluateVideoDurationTokens } from "lib/src/stage/elements";
import {
    REACTION_TICKETS,
    USER_VIDEO_SHARE_MULTIPLIER,
} from "lib/src/stage/stages";
import { EmojiButton } from "../EmojiButton";
import { Emoji } from "../Emoji";

export const WatchAdDialog = ({
    ad = {},
    onCancel,
    onClaim,
    isPreview,
    open,
    ...props
}) => {
    const { t } = useTranslation();
    const turnstile = useTurnstile();

    const [userContext, dispatch] = useContext(UserContext);
    const [applicationContext, applicationDispatch] =
        useContext(ApplicationContext);

    const [duration, setDuration] = useState(null);
    const [currentTime, setCurrentTime] = useState(null);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const [isLoadingClaim, setIsLoadingClaim] = useState(false);
    const [isEndScreenVisible, setIsEndScreenVisible] = useState(false);
    const [turnstileToken, setTurnstileToken] = useState(null);
    const [websiteClicked, setWebsiteClicked] = useState(false);
    const [reaction, setReaction] = useState(null);

    const init = () => {
        setDuration(null);
        setCurrentTime(null);
        setIsVideoLoaded(false);
        setIsEndScreenVisible(false);
        setReaction(null);
    };

    useEffect(() => {
        init();
    }, [open]);

    const campaign = ad?.campaign;

    if (!campaign) {
        return null;
    }

    const handleLoadedData = (e) => {
        setIsVideoLoaded(true);
    };

    const handleTimeUpdate = (e) => {
        if (!duration) {
            setDuration(Math.ceil(e.target?.duration || 0));
        }

        setCurrentTime(Math.ceil(e.target.currentTime || 0));
    };

    const handleCancel = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (onCancel) {
            onCancel();
        }

        init();
    };

    const validate = () => {
        if (
            !isVideoLoaded ||
            duration === null ||
            currentTime === null ||
            duration - currentTime !== 0
        ) {
            return false;
        }

        return true;
    };

    const handleClaim = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!ad) {
            return;
        }

        if (isPreview) {
            onClaim();
            return;
        }

        setIsLoadingClaim(true);

        try {
            const claimResponse = await raffleUtils.claimTicket(
                ad?.raffle?.uuid,
                {
                    adKey: ad.adKey,
                    turnstileToken,
                    websiteClicked,
                    reaction,
                }
            );

            if (claimResponse.status === 200) {
                const tickets = evaluateVideoDurationTokens(
                    campaign.videoDuration,
                    USER_VIDEO_SHARE_MULTIPLIER
                );

                dispatch({
                    type: "SET_USER",
                    payload: {
                        ...userContext.user,
                        tickets:
                            Number(userContext.user.tickets || 0) + tickets,
                    },
                });

                if (reaction) {
                    dispatch({ type: "SET_LAST_REACTION", payload: reaction });
                }

                const updatedRaffle = claimResponse.data.data.raffle;
                const updatedRaffleUser = claimResponse.data.data;

                applicationDispatch({
                    type: "UPDATE_RAFFLE_WITH_USER",
                    payload: {
                        raffle: updatedRaffle,
                        raffleUser: updatedRaffleUser,
                    },
                });

                onClaim(claimResponse.data.data);
            } else {
                turnstile.reset();
            }
        } catch (err) {
            console.error(err);
            setIsLoadingClaim(false);

            turnstile.reset();
            setTurnstileToken(null);
            init();
        }

        setIsLoadingClaim(false);
        init();
    };

    const handleVideoError = (e) => {
        if (window.location.hostname === "localhost") {
            e.target.src = "/videos/demo_video.mp4";
        }
    };

    const handleVideoEnd = (e) => {
        if (!e.target) {
            return;
        }

        setIsEndScreenVisible(true);
    };

    const handleLinkClick = () => {
        setWebsiteClicked(true);
    };

    const handleReactionClick = (value, e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (value === reaction) {
            value = null;
        }

        setReaction(value);
    };

    const evaluatedTickets = evaluateVideoDurationTokens(
        campaign.videoDuration,
        USER_VIDEO_SHARE_MULTIPLIER
    );

    const tickets =
        reaction === null
            ? evaluatedTickets - REACTION_TICKETS
            : evaluatedTickets;

    return (
        <Dialog size="big" open={open} {...props}>
            {!isPreview && (
                <Turnstile
                    appearance="execute"
                    sitekey={process.env.REACT_APP_TURNSTILE_KEY}
                    style={{ width: "100%" }}
                    theme="dark"
                    size="flexible"
                    fixedSize
                    onVerify={(token) => {
                        setTurnstileToken(token);
                    }}
                    onExpire={() => {
                        setTurnstileToken(null);
                        turnstile.reset();
                    }}
                    refreshExpired="auto"
                    className="mb-2"
                />
            )}
            <a
                href={campaign?.websiteUrl}
                target="_blank"
                onClick={handleLinkClick}
            >
                <div className="relative">
                    <div>
                        <AdVideo
                            ad={ad}
                            onPlaying={handleLoadedData}
                            onTimeUpdate={handleTimeUpdate}
                            onError={handleVideoError}
                            onEnded={handleVideoEnd}
                        />
                        <div className="absolute left-0 bottom-0 w-full p-4 flex z-20">
                            <div className="grow">
                                <Button
                                    onClick={handleCancel}
                                    variant="tertiary"
                                >
                                    {t("button.cancel")}
                                </Button>
                            </div>
                            <Button
                                disabled={
                                    !isEndScreenVisible || !turnstileToken
                                }
                                onClick={handleClaim}
                                isLoading={isLoadingClaim}
                                rightIcon={faTicket}
                                variant="yellow"
                                progress={
                                    !isEndScreenVisible
                                        ? Math.ceil(
                                              (currentTime / duration) * 100
                                          ) || 0
                                        : undefined
                                }
                            >
                                {t("button.claim_x", { x: tickets })}
                            </Button>
                        </div>
                        {!isVideoLoaded && (
                            <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center z-10">
                                <div className="text-center">
                                    <LogoLoader
                                        className="w-8 h-8"
                                        rowClassName="h-2"
                                    />
                                    <p className="text-secondary text-sm">
                                        {t("text.loading_ad")}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div
                            className={classNames(
                                "absolute left-0 top-0 w-full h-full bg-black/80 rounded-lg transition p-4",
                                {
                                    "opacity-1 pointer-events-auto":
                                        isEndScreenVisible,
                                    "opacity-0 pointer-events-none":
                                        !isEndScreenVisible,
                                }
                            )}
                        >
                            <div>
                                <p
                                    className="mb-2 text-primary"
                                    dangerouslySetInnerHTML={{
                                        __html: t("text.rate_campaign", {
                                            value: 2,
                                        }),
                                    }}
                                />
                                <div className="grid gap-4 grid-cols-5">
                                    <EmojiButton
                                        active={
                                            reaction === null ||
                                            reaction === "veryNegative"
                                        }
                                        onClick={(e) =>
                                            handleReactionClick(
                                                "veryNegative",
                                                e
                                            )
                                        }
                                        emoji="hmhmhmhm"
                                    />
                                    <EmojiButton
                                        active={
                                            reaction === null ||
                                            reaction === "negative"
                                        }
                                        onClick={(e) =>
                                            handleReactionClick("negative", e)
                                        }
                                        emoji="sleepy"
                                    />
                                    <EmojiButton
                                        active={
                                            reaction === null ||
                                            reaction === "neutral"
                                        }
                                        onClick={(e) =>
                                            handleReactionClick("neutral", e)
                                        }
                                        emoji="neutral"
                                    />
                                    <EmojiButton
                                        active={
                                            reaction === null ||
                                            reaction === "positive"
                                        }
                                        onClick={(e) =>
                                            handleReactionClick("positive", e)
                                        }
                                        emoji="happy"
                                    />
                                    <EmojiButton
                                        active={
                                            reaction === null ||
                                            reaction === "veryPositive"
                                        }
                                        onClick={(e) =>
                                            handleReactionClick(
                                                "veryPositive",
                                                e
                                            )
                                        }
                                        emoji="stunned"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
            <div className="mt-4">
                <div className="flex items-center">
                    {!!campaign?.advertiser?.logoUrl && (
                        <img
                            src={campaign?.advertiser?.logoUrl}
                            className="h-6 inline-block mr-2 "
                        />
                    )}
                    <h4 className="font-bold">{campaign?.advertiser?.name}</h4>
                </div>
                {(campaign?.description ||
                    campaign?.advertiser?.description) && (
                    <p className="mt-1 text-primary text-sm whitespace-break-spaces max-h-20 lg:max-h-44 truncate">
                        {campaign?.description ||
                            campaign?.advertiser?.description}
                    </p>
                )}
            </div>
        </Dialog>
    );
};
