export const userContextReducer = (prevState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...prevState,
                user: action.payload,
            };
        case "SET_LANGUAGE": {
            return {
                ...prevState,
                language: action.payload,
            };
        }
        case "SET_LOADING": {
            return {
                ...prevState,
                isLoading: action.payload,
            };
        }
        case "SET_LAST_REACTION":
            return {
                ...prevState,
                lastReaction: action.payload,
            };
        default: {
            return prevState;
        }
    }
};
