import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import classNames from "classnames";

export const Label = ({ children, required, className, ...props }) => {
    return (
        <label
            className={classNames(
                "flex items-center mb-1 text-primary text-opacity-90 font-bold",
                className
            )}
            {...props}
        >
            {children}
            {required && (
                <Icon className="!text-red ml-2 text-xs" icon={faAsterisk} />
            )}
        </label>
    );
};
