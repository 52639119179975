import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    faEye,
    faPencil,
    faTimes,
    faUpDownLeftRight,
    faVenusMars,
} from "@fortawesome/free-solid-svg-icons";
import { Icon } from "../Icon";
import { useDrop } from "react-dnd";
import { useContext, useEffect, useState } from "react";
import { sortStageValues, StageRenderer } from "./StageRenderer";
import { StageEditorSidebar } from "./StageEditorSidebar";
import { StageEditorContext } from "./StageEditorContext";
import { Card } from "../Card";
import { campaignUtils } from "../../utils/campaignUtils";
import useQuestion from "../../hooks/useQuestion";
import { Button } from "../Button";
import { StageDialog } from "../dialogs/StageDialog";

export const StageEditor = ({
    campaign,
    duration,
    value,
    className,
    onChange,
    onUpdate,
    ...props
}) => {
    const { t } = useTranslation();
    const [stageEditorContext, dispatch] = useContext(StageEditorContext);
    const { question, QuestionDialog } = useQuestion();

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isEditing, setIsEditing] = useState(!campaign?.stageJson?.length);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);

    const [{ isOver }, dropRef] = useDrop(
        () => ({
            accept: "sidebar-element",
            drop: (config) => {
                const nextId =
                    value?.length > 0
                        ? Math.max(...value.map((v) => v.id)) + 1
                        : 0;
                const nextSort =
                    value?.length > 0
                        ? Math.max(...value.map((v) => v.sort)) + 1
                        : 1;

                const newItem = {
                    id: nextId,
                    type: config.type,
                    sort: nextSort,
                    props: config.defaultProps || {},
                };

                dispatch({
                    type: "SET_VALUE",
                    payload: [newItem],
                });

                onChange([...(value || []), newItem]);

                return { name: "stage-editor" };
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
            }),
        }),
        [value, onChange]
    );

    const handleSave = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!validate()) {
            return;
        }

        setIsLoadingSave(true);

        const updatePayload = {
            stageJson: JSON.stringify(value),
            stageDuration: duration,
        };

        try {
            const updateResponse = await campaignUtils.update(
                campaign?.uuid,
                updatePayload
            );

            if (updateResponse.status === 200) {
                onUpdate(updateResponse.data.data);

                dispatch({
                    type: "SET_VALUE",
                    payload: updateResponse.data.data.stageJson,
                });

                setIsEditing(false);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingSave(false);
        }

        setIsLoadingSave(false);
    };

    const validate = () => {
        if (
            JSON.stringify(value) === JSON.stringify(campaign.stageJson) &&
            campaign.stageDuration === duration
        ) {
            return false;
        }

        return true;
    };

    const handleCancel = async () => {
        if (JSON.stringify(value) === JSON.stringify(campaign.stageJson)) {
            setIsEditing(false);

            return;
        }

        if (
            !(await question(
                t("title.cancel_editing"),
                t("question.cancel_editing"),
                t("button.continue"),
                t("button.cancel"),
                "primary"
            ))
        ) {
            return;
        }

        dispatch({
            type: "SET_VALUE",
            payload: campaign.stageJson || [],
        });

        setIsEditing(!campaign.stageJson?.length);
    };

    const sortedValue = (value || []).sort(sortStageValues);

    return (
        <div className={classNames(className)} {...props}>
            <div className="flex justify-end space-x-2 mb-2">
                {value?.length > 0 &&
                    (isEditing ? (
                        <Button
                            variant="secondary"
                            onClick={handleCancel}
                            rightIcon={faTimes}
                        >
                            {t("button.cancel")}
                        </Button>
                    ) : (
                        <Button
                            variant="secondary"
                            onClick={() => setIsEditing(true)}
                            rightIcon={faPencil}
                        >
                            {t("button.edit")}
                        </Button>
                    ))}
                <Button
                    variant="secondary"
                    onClick={() => setIsPreviewOpen(true)}
                    rightIcon={faEye}
                    disabled={value?.length <= 0}
                >
                    {t("button.preview")}
                </Button>
                <Button
                    isLoading={isLoadingSave}
                    onClick={handleSave}
                    disabled={!validate()}
                >
                    {t("button.save")}
                </Button>
            </div>
            <Card className="!p-4">
                <div
                    className={classNames(
                        "grid gap-4 rounded-lg bg-opacity-5 w-full"
                    )}
                >
                    {isEditing && (
                        <StageEditorSidebar onChange={onChange} value={value} />
                    )}
                    <div
                        // ref={dropRef}
                        className={classNames("box-border")}
                    >
                        {!value?.length ? (
                            <div
                                className={classNames(
                                    "flex items-center rounded-lg flex-col justify-center h-full",
                                    {
                                        "bg-opacity-5": !isOver,
                                        "bg-opacity-10 outline-2 outline-cash outline-dashed":
                                            isOver,
                                    }
                                )}
                                ref={dropRef}
                            >
                                <Icon
                                    icon={faUpDownLeftRight}
                                    className="mb-4 !text-secondary"
                                    size="xl"
                                />
                                <p className="text-center px-16 text-sm text-secondary">
                                    {t("text.drag_and_drop")}
                                </p>
                            </div>
                        ) : (
                            <div>
                                <StageRenderer
                                    value={value}
                                    campaign={campaign}
                                    // Don't know why it's not working without undefined as fallback
                                    isEditor={isEditing || undefined}
                                    isEditing={isEditing}
                                    onElementSort={(index, sort, element) => {
                                        let updatedValue = sortedValue.filter(
                                            (v) => v.id !== element.id
                                        );

                                        updatedValue.splice(
                                            index > element.sort
                                                ? index - 1
                                                : index,
                                            0,
                                            element
                                        );

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        dispatch({
                                            type: "SET_VALUE",
                                            payload: updatedValue,
                                        });

                                        onChange(updatedValue);

                                        return { name: "stage-editor" };
                                    }}
                                    onElementDrop={(index, config) => {
                                        let nextId =
                                            value?.length > 0
                                                ? Math.max(
                                                      ...value.map((v) => v.id)
                                                  ) + 1
                                                : 0;

                                        const newItem = {
                                            id: nextId,
                                            type: config.type,
                                            props: config.defaultProps || {},
                                        };

                                        let updatedValue = [...sortedValue];

                                        // Remove the moved element from the array

                                        updatedValue.splice(index, 0, newItem);

                                        updatedValue = updatedValue.map(
                                            (v, i) => ({
                                                ...v,
                                                sort: i,
                                            })
                                        );

                                        dispatch({
                                            type: "SET_VALUE",
                                            payload: updatedValue,
                                        });

                                        onChange(updatedValue);

                                        return { name: "stage-editor" };
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Card>
            <StageDialog
                campaign={campaign}
                value={value}
                open={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                isPreview
            />
            <QuestionDialog />
        </div>
    );
};
