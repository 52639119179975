import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Headline } from "./Headline";
import { Button } from "../../Button";
import { Card } from "../../Card";
import { Price } from "../../Price";
import {
    useElementConfig,
    useStageElementEditState,
} from "../../context/StageElementContext";
import { StageElementTextEditWrapper } from "../StageElementTextEditWrapper";
import { StageElementGroupEditWrapper } from "../StageElementGroupEditWrapper";
import { AddMVPElementButton } from "../controls/MVP";
import { StageElementImageEditWrapper } from "../StageElementImageEditWrapper";

export const ProductSlider = ({
    title,
    titleSize,
    description,
    products = [],
}) => {
    const isEditing = useStageElementEditState();

    const renderProducts = products.map((v, index) => (
        <SwiperSlide
            className="!h-auto"
            key={`product-slider-item${title}-${index}`}
        >
            <ProductSliderProduct product={v} index={index} />
        </SwiperSlide>
    ));

    return (
        <div className="w-full overflow-hidden">
            <div className="space-y-4">
                <div>
                    <Headline title={title} size={titleSize} />
                    <StageElementTextEditWrapper argKey="description">
                        {!!description && (
                            <p className="text-secondary mt-1">{description}</p>
                        )}
                    </StageElementTextEditWrapper>
                </div>
                <Swiper
                    className="!pb-10 !static"
                    breakpoints={{
                        400: {
                            slidesPerView:
                                products.length > 1 ? 2.3 : products.length,
                        },
                        640: {
                            slidesPerView:
                                products.length > 3 ? 3.2 : products.length,
                        },
                        1024: {
                            slidesPerView:
                                products.length > 4 ? 4.2 : products.length,
                        },
                    }}
                    spaceBetween={8}
                    slidesPerView={1.3}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    style={{
                        "--swiper-pagination-color": "#1CF683",
                        "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
                        "--swiper-pagination-bullet-inactive-opacity": "0.2",
                        "--swiper-pagination-bullet-size": "8px",
                        "--swiper-pagination-bullet-horizontal-gap": "4px",
                    }}
                >
                    {renderProducts}
                    {isEditing && (
                        <SwiperSlide className="!h-auto">
                            <AddMVPElementButton
                                className="w-full h-full"
                                wrapperClassName="w-full h-full"
                                mvpKey="products"
                            />
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    );
};

const ProductSliderProduct = ({ product, index, className }) => {
    const elementConfig = useElementConfig();

    if (!product) {
        return null;
    }

    return (
        <Card className="!p-1 h-full !static">
            <div className="flex flex-col space-y-2 p-2 h-full">
                <StageElementImageEditWrapper
                    argKey="image"
                    mvpIndex={index}
                    mvpKey="products"
                >
                    <img
                        className="w-full rounded-lg"
                        src={product.image?.blob || product.image}
                        draggable={false}
                    />
                </StageElementImageEditWrapper>
                <div className="grow">
                    <Headline
                        title={product.title}
                        size="small"
                        mvpIndex={index}
                        mvpKey="products"
                        className="mb-1"
                    />
                    <pre className="text-tertiary text-sm">
                        <StageElementTextEditWrapper
                            argKey="description"
                            mvpIndex={index}
                            mvpKey="products"
                        >
                            {product.description}
                        </StageElementTextEditWrapper>
                    </pre>
                </div>
                <StageElementGroupEditWrapper
                    mvpIndex={index}
                    mvpKey="products"
                    title="title.price"
                    args={{
                        price: elementConfig?.arguments?.products?.arguments
                            ?.price,
                        discount:
                            elementConfig?.arguments?.products?.arguments
                                ?.discount,
                    }}
                >
                    <Price discount={product.discount} price={product.price} />
                </StageElementGroupEditWrapper>
                {!!product.buttonText && product.buttonLink && (
                    <a
                        href={product.buttonLink}
                        target={product.buttonLink === "#" ? "_self" : "_blank"}
                    >
                        <StageElementGroupEditWrapper
                            title="title.button"
                            mvpIndex={index}
                            mvpKey="products"
                            args={{
                                buttonText:
                                    elementConfig?.arguments?.products
                                        ?.arguments?.buttonText,
                                buttonLink:
                                    elementConfig?.arguments?.products
                                        ?.arguments?.buttonLink,
                            }}
                        >
                            <Button
                                size="small"
                                className="block w-full"
                                wrapperClassName="w-full"
                                buttonText={product.buttonText}
                            />
                        </StageElementGroupEditWrapper>
                    </a>
                )}
            </div>
        </Card>
    );
};
