import { faAd, faTicket, faUser } from "@fortawesome/free-solid-svg-icons";
import { Icon } from "./Icon";
import { Link } from "react-router-dom";
import { Loader } from "./Loader";
import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { UserContext } from "./context/UserContext";
import { useTranslation } from "react-i18next";
import { AdvertiserLogo } from "./AdvertiserLogo";
import { NumberFormatter, PriceFormatter } from "../helpers/number.helper";
import { ApplicationContext } from "./context/ApplicationContext";
import { Circle } from "./Circle";
import { Emoji } from "./Emoji";

export const Profile = ({ user, isLoading }) => {
    const [userContext] = useContext(UserContext);
    const [applicationContext] = useContext(ApplicationContext);
    const { t } = useTranslation();

    const ticketsRef = useRef();

    const [isUpdatePlaying, setIsUpdatePlaying] = useState(false);
    const [previousTickets, setPreviousTickets] = useState(undefined);
    const [emoji, setEmoji] = useState(null);

    useEffect(() => {
        setEmoji(null);

        setTimeout(() => {
            let e;

            switch (userContext?.lastReaction) {
                case "veryNegative":
                    e = "hmhmhmhm";

                    break;
                case "negative":
                    e = "sleepy";

                    break;
                case "positive":
                    e = "happy";

                    break;
                case "veryPositive":
                    e = "stunned";

                    break;
                case "neutral":
                default:
                    e = "neutral";
            }

            setEmoji(e);
        }, 50);
    }, [userContext?.lastReaction]);

    useEffect(() => {
        if (
            !!previousTickets &&
            !!user?.tickets &&
            user.tickets !== previousTickets
        ) {
            setIsUpdatePlaying(true);

            setTimeout(() => {
                setPreviousTickets(user?.tickets);

                setIsUpdatePlaying(false);
            }, 1000);
        } else {
            setPreviousTickets(user?.tickets);
        }
    }, [user?.tickets, previousTickets]);

    if (isLoading) {
        return (
            <div className="flex items-center">
                <div className="mr-2 flex items-end flex-col">
                    <Loader className="h-[12px] w-24 mb-1" />
                    <Loader className="h-[12px] w-14" />
                </div>
                <Loader className="w-[34px] h-[34px] !rounded-full" />
            </div>
        );
    }

    const balance = applicationContext.wonRaffles.reduce(
        (prev, v) => prev + v.raffle?.ticketValue * v.raffle?.ticketCount,
        0
    );

    return (
        <div className="flex items-center space-x-2">
            <Link to="/play/account" title="Your account">
                <div className="flex items-center">
                    {!!user?.username && (
                        <div className="mr-2 text-right">
                            <p className="text-xs font-bold">
                                {user.username}{" "}
                                <span className="text-tertiary">
                                    #{user.id}
                                </span>
                            </p>
                            <div className="flex justify-end space-x-1">
                                <p
                                    className={classNames(
                                        "flex items-center text-xs transition-all mr-1",
                                        {
                                            "!text-cash": !isUpdatePlaying,
                                            "!text-white": isUpdatePlaying,
                                        }
                                    )}
                                >
                                    <span
                                        className="flex h-4 overflow-hidden"
                                        ref={ticketsRef}
                                    >
                                        <span
                                            className={classNames(
                                                "inline-flex flex-col",
                                                {
                                                    "-translate-y-4":
                                                        !isUpdatePlaying,
                                                    "-translate-y-0 transition-all":
                                                        isUpdatePlaying,
                                                }
                                            )}
                                        >
                                            <span
                                                className={classNames(
                                                    "transition-all ml-1",
                                                    {
                                                        "!text-cash":
                                                            !isUpdatePlaying,
                                                        "!text-white":
                                                            isUpdatePlaying,
                                                    }
                                                )}
                                            >
                                                {NumberFormatter.format(
                                                    user.tickets || 0
                                                )}
                                            </span>
                                            <span>
                                                {NumberFormatter.format(
                                                    previousTickets || 0
                                                )}
                                            </span>
                                        </span>
                                    </span>
                                    <Icon
                                        icon={faTicket}
                                        className={classNames(
                                            "transition-all ml-1",
                                            {
                                                "!text-cash": !isUpdatePlaying,
                                                "!text-white": isUpdatePlaying,
                                            }
                                        )}
                                    />
                                </p>
                                {balance > 0 && (
                                    <span className="text-xs">
                                        {PriceFormatter.format(balance)}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    <Emoji
                        emoji={emoji}
                        className="w-[37px] h-[37px]"
                        play={!!emoji}
                    />
                </div>
            </Link>
            {!userContext?.isLoading && (
                <div className="hidden xs:block">
                    {!userContext?.user?.advertiser ? (
                        <Link
                            to="/play/account/become-an-advertiser"
                            title="Become an advertiser"
                        >
                            <Circle className="bg-white/10 w-8 h-8">
                                <Icon
                                    icon={faAd}
                                    size="sm"
                                    className="!text-yellow"
                                />
                            </Circle>
                        </Link>
                    ) : (
                        <Link
                            to="/play/account/advertisements"
                            title="Advertisement Center"
                        >
                            <AdvertiserLogo
                                advertiser={userContext.user?.advertiser}
                                className={"rounded-full"}
                            />
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};
