import { StageElementTextEditWrapper } from "../StageElementTextEditWrapper";
import { Headline } from "./Headline";

export const Paragraph = ({ text, title, titleSize }) => {
    return (
        <div>
            <pre className="!text-secondary">
                <Headline size={titleSize} title={title} className="mb-2">
                    <StageElementTextEditWrapper argKey="title">
                        {title}
                    </StageElementTextEditWrapper>
                </Headline>
                <StageElementTextEditWrapper argKey="text">
                    {text}
                </StageElementTextEditWrapper>
            </pre>
        </div>
    );
};
