import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
    Area,
    AreaChart,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { Button } from "./Button";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export const Chart = ({
    data,
    areas,
    className,
    wrapperClassName,
    isLoading,
    filterable,
    ...props
}) => {
    const { t } = useTranslation();

    const [filters, setFilters] = useState((areas || []).map((v) => v.key));

    const handleFilterToggle = (area) => {
        if (filters.includes(area.key)) {
            setFilters(filters.filter((v) => v !== area.key));
        } else {
            setFilters([...filters, area.key]);
        }
    };

    let filteredAreas = areas;

    if (filterable) {
        filteredAreas = areas.filter((v) => filters.includes(v.key));
    }

    return (
        <div className="relative w-full rounded">
            {!isLoading && data.length < 1 && (
                <div className="absolute w-full left-0 top-0 text-center">
                    <span className="block text-lg text-tertiary mt-28 font-semibold">
                        {t("text.no_data_available")}
                    </span>
                </div>
            )}
            <ResponsiveContainer
                className={classNames("!h-[300px]", wrapperClassName)}
            >
                <AreaChart
                    data={data}
                    className={classNames("relative", className)}
                    {...props}
                >
                    <defs>
                        <linearGradient
                            id="color_cash"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                stopColor="#1CF683"
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="100%"
                                stopColor="#1CF683"
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                        <linearGradient
                            id="color_ar_blue"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                stopColor="#3b82f6"
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="100%"
                                stopColor="#3b82f6"
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                        <linearGradient
                            id="color_ar_yellow"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                stopColor="#fcd34d"
                                stopOpacity={0.8}
                            />
                            <stop
                                offset="100%"
                                stopColor="#fcd34d"
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                        <linearGradient
                            id="color_default"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                        >
                            <stop
                                offset="0%"
                                stopColor="#FFF"
                                stopOpacity={0.4}
                            />
                            <stop
                                offset="100%"
                                stopColor="#FFF"
                                stopOpacity={0.1}
                            />
                        </linearGradient>
                    </defs>
                    <CartesianGrid
                        strokeDasharray="3 3"
                        stroke="rgba(255, 255, 255, 0.1)"
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <XAxis
                        dataKey="name"
                        fontSize="14px"
                        interval="equidistantPreserveStart"
                    />
                    <YAxis fontSize="14px" width={40} />
                    {(filteredAreas || []).map((area) => (
                        <Area
                            key={`chart-area-${area.title}`}
                            type="basis"
                            dataKey={area.key}
                            name={area.title}
                            stroke={area.color}
                            fill={`url(#color_${area.color})`}
                            fillOpacity={0.4}
                            animationDuration={500}
                            animationEasing="ease-out"
                        />
                    ))}
                </AreaChart>
            </ResponsiveContainer>
            {filterable && (
                <div className="text-right">
                    {(areas || []).map((area) => {
                        let total = data.reduce(
                            (prev, curr) => prev + curr[area.key],
                            0
                        );

                        return (
                            <Button
                                variant="tertiary"
                                icon={filters.includes(area.key) && faCheck}
                                iconProps={{ size: "xs" }}
                                onClick={() => handleFilterToggle(area)}
                                size="small"
                                className={classNames({
                                    "text-cash": area.color === "cash",
                                    "text-blue": area.color === "ar_blue",
                                    "text-yellow": area.color === "ar_yellow",
                                    "text-secondary":
                                        !area.color || area.color === "default",
                                })}
                                key={`chart-filter-${area.title}`}
                            >
                                {`${total} ${area.title}`}
                            </Button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-black border-card-border rounded-md p-4">
                <p className="font-bold mb-1">{label}</p>
                {payload.map((v) => (
                    <p
                        className={classNames({
                            "text-cash": v.color === "cash",
                            "text-blue": v.color === "ar_blue",
                            "text-yellow": v.color === "ar_yellow",
                            "text-secondary": !v.color || v.color === "default",
                        })}
                    >
                        {v.value} {v.name}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};
