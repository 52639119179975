import { Dialog } from "../Dialog";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "../../lib/stripe";
import { useContext, useEffect, useState } from "react";
import { Button } from "../Button";
import { AdPaymentMethodForm } from "../forms/AdPaymentMethodForm";
import { SubPages, SubPage } from "../SubPages";
import { UserContext } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { campaignUtils } from "../../utils/campaignUtils";
import {
    evaluateStageTokens,
    evaluateVideoDurationTokens,
} from "lib/src/stage/elements";

export const PublishDialog = ({
    onClose,
    open,
    campaign,
    onUpdate,
    ...props
}) => {
    const { t } = useTranslation();

    const [userContext] = useContext(UserContext);

    const [intent, setIntent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPublish, setIsLoadingPublish] = useState(false);
    const [currentPage, setCurrentPage] = useState(
        !!userContext?.user?.advertiser?.stripe?.paymentMethodId ? 1 : 0
    );

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    useEffect(() => {
        setCurrentPage(
            !!userContext?.user?.advertiser?.stripe?.paymentMethodId ? 1 : 0
        );
    }, [userContext?.user]);

    const options = {
        // passing the SetupIntent's client secret
        clientSecret:
            userContext?.user?.advertiser?.stripe?.setupIntentClientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: "night",
            labels: "floating",
        },
    };

    const handlePublish = async () => {
        if (![0, 2, 5].includes(campaign.status)) {
            return;
        }

        setIsLoadingPublish(true);

        try {
            const publishResponse = await campaignUtils.publish(campaign?.uuid);

            if (publishResponse.status === 200) {
                onUpdate(publishResponse.data.data);
            }
        } catch (err) {
            console.error(err);
            setIsLoadingPublish(false);
        }

        handleClose();

        setIsLoadingPublish(false);
    };

    const videoTokens = evaluateVideoDurationTokens(campaign?.videoDuration);
    const stageTokens = evaluateStageTokens(
        campaign?.stageJson,
        campaign?.stageDuration
    );

    return (
        <Dialog
            title={t("title.publish")}
            onClose={handleClose}
            open={open}
            {...props}
        >
            <SubPages
                currentPage={currentPage}
                setPage={(v) => setCurrentPage(v)}
            >
                <SubPage>
                    {!userContext?.user?.advertiser?.stripe?.paymentMethodId &&
                        userContext?.user?.advertiser?.stripe
                            ?.setupIntentClientSecret && (
                            <Elements stripe={stripePromise} options={options}>
                                <AdPaymentMethodForm
                                    returnUrl={`${window.location.origin}/payment-method-added?referrer=${window.location.href}`}
                                />
                            </Elements>
                        )}
                </SubPage>
                <SubPage>
                    <p className="mb-4 whitespace-break-spaces text-secondary">
                        {t("text.publish_campaign_1")}
                    </p>
                    <p className="text-secondary mb-2">
                        {t("label.price_overview")}
                    </p>
                    <ul>
                        {videoTokens > 0 && (
                            <li className="text-secondary">
                                {t("text.tokens_per_view", {
                                    value: videoTokens,
                                })}
                            </li>
                        )}
                        {campaign?.stageJson?.length &&
                            campaign?.stageDuration > 0 && (
                                <li className="text-secondary">
                                    {t("text.tokens_per_stage_view", {
                                        value: stageTokens,
                                    })}
                                </li>
                            )}
                    </ul>
                    <div className="text-right">
                        <Button
                            onClick={handlePublish}
                            disabled={![0, 2, 5].includes(campaign?.status)}
                            isLoading={isLoadingPublish}
                        >
                            {t("button.publish")}
                        </Button>
                    </div>
                </SubPage>
            </SubPages>
        </Dialog>
    );
};
